export default {
    headers: {
        index: 'Trang chủ',
        message: 'Thông báo',
        query: 'Tra cứu vận chuyển',
        login: 'Đăng nhập',
        register: 'Đăng ký',
        logout: 'Đăng xuất',
        member: 'Trung tâm vận chuyển'
    },
    utils: {
        title: "Xác nhận Đăng xuất",
        confirm: "Đăng nhập lại",
        cancel: "Hủy bỏ",
        message: "Phiên đăng nhập của bạn đã hết hạn, bạn có thể hủy bỏ để quay lại trang chủ hoặc đăng nhập lại"
    },
    advantageBox: {
        team: {
            title: 'Đội ngũ chuyên nghiệp',
            subtitle1: 'Sở hữu đội ngũ chuyên nghiệp giàu kinh nghiệm',
            subtitle2: 'Cung cấp dịch vụ hậu mãi toàn diện'
        },
        qualityControl: {
            title: 'Kiểm soát chất lượng và bảo đảm an toàn',
            subtitle1: 'Kiểm soát và bảo đảm an toàn sáng tạo, mỗi bước đều được kiểm soát chất lượng nghiêm ngặt',
            subtitle2: 'Đảm bảo hàng hóa an toàn và nguyên vẹn'
        },
        innovativeTechnology: {
            title: 'Ứng dụng công nghệ đổi mới',
            subtitle1: 'Sử dụng hệ thống vận chuyển tiên tiến để tăng hiệu suất vận hành, theo dõi trạng thái hàng hóa theo thời gian thực',
            subtitle2: 'Đảm bảo an toàn và nhanh chóng đến nơi đích'
        },
        diverseService: {
            title: 'Dịch vụ đa dạng',
            subtitle1: 'Các lựa chọn vận chuyển linh hoạt',
            subtitle2: 'Gói dịch vụ đóng gói có thể tùy chỉnh'
        }
    },
    immediate: {
        title: "Giao ngay",
        placeholderTimer: "Chọn thời gian giao hàng",
        timer: "Thời gian giao hàng",
        channel: "Kênh",
        placeholderChannel: "Chọn kênh",
        rem: "Ghi chú",
        placeholderRem: "Nếu có yêu cầu khác hoặc cần lưu ý đặc biệt, vui lòng để lại thông tin yêu cầu của bạn",
        submit: "Gửi",
        submitSuccess: "Gửi thành công!",
        addWarning: "Số lượng thêm đã đạt đến giới hạn!"
    },
    exhibitBox: {
        upper: {
            members: '10000+ Thành viên',
            dailyGoodsWarehousing: 'Nhập kho hàng hóa hàng ngày 30000+',
            dailyGoodsStorage: 'Lưu kho hàng hóa hàng ngày 30000+',
            dailyOrders: 'Đơn hàng hàng ngày 10000+',
            dailyShipments: 'Giao hàng hàng ngày 10000+'
        },
        lower: {
            dailyShipments: 'Giao hàng hàng ngày 10000+',
            cooperativePickupPoints: 'Điểm tự lấy hàng hợp tác 600+'
        }
    },
    toolBox: {
        teach: {
            title: 'Hướng dẫn vận chuyển',
            subtitle: 'Hướng dẫn hình ảnh và video'
        },
        volume: {
            title: 'Tính toán thể tích',
            subtitle: 'Tính toán trọng lượng thể tích hàng hóa'
        },
        shipping: {
            title: 'Tính cước phí vận chuyển',
            subtitle: 'Tính toán cước phí đơn hàng'
        },
        chargeIntroduction: {
            title: 'Giới thiệu phí',
            subtitle: 'Giới thiệu phí và các hướng dẫn liên quan'
        }
    },
    introduce: {
        destination: 'Điểm đến',
        CalculationModeName: 'Chế độ tính toán',
        CarryModeName: 'Chế độ chuyển phát',
        CarrierNames: 'Nhà vận chuyển',
        GoodsTypeNames: 'Loại hàng hóa',
        TimerNames: 'Thời gian vận chuyển',
        range: 'Khoảng cách',
        firstWeight: 'Trọng lượng đầu tiên',
        unitPrice: 'Giá đơn vị',
        surchargeFormula: 'Công thức phụ thu (Z biểu thị trọng lượng)',
        unit: 'Đơn vị',
        FirstWeight: "Trọng lượng ban đầu",
        FirstPrice: "Giá đầu tiên",
        FirstSurcharge: "Phụ phí đầu tiên"
    },
    paymentCard: {
        paymentMethod: 'Phương thức thanh toán',
        wechatPay: 'Thanh toán Wechat',
        alipayPay: 'Thanh toán Alipay',
        balancePay: 'Thanh toán số dư',
        paymentAmount: 'Số tiền thanh toán',
        rechargeAmount: "Số tiền nạp",
        paidInAmount: "Số tiền thực"
    },
    QrCodePay: {
        paymentAmount: 'Số tiền thanh toán',
        orderNumber: 'Số đơn hàng',
        orderDescription: 'Mô tả đơn hàng',
        bankDeposit: "Ngân hàng mở tài khoản",
        openingName: "Tên chủ tài khoản",
        account: "Tài khoản",
        prompt: "Tải lên giấy chứng nhận thanh toán",
        transactionNo: "Số dòng giao dịch",
        onSubmit: "Gửi đơn đăng ký",
        placeholderTransactionNo: "Vui lòng điền số dòng giao dịch",
        placeholderOffline: "Vui lòng tải lên giấy chứng nhận giao dịch",
        successMsg: "Gửi thành công, vui lòng chờ xem xét bởi quản lý!",
        rem: "Ghi chú"
    },
    agent: {
        title: "Trở thành đại lý, thu nhập không ngừng",
        referralCode: "Mã giới thiệu của tôi",
        download: "Tải về mã QR đại lý",
        copyLink: "Sao chép liên kết đại lý",
        WithdrawalMoney: "Thu nhập đại lý hiện tại",
        promotionNumber: "Số lượng thành viên giới thiệu",
        withdrawal: "Rút tiền thu nhập vào số dư",
        member: "Chi tiết thành viên",
        id: "ID thành viên",
        name: "Tên thành viên",
        createTime: "Thời gian đăng ký",
        lastLoginTime: "Thời gian hoạt động gần đây",
        withdrawalIncome: "Thu nhập có thể rút",
        allWithdrawal: "Rút tất cả",
        label: "Nhập số tiền rút:",
        submit: "Xác nhận rút tiền",
        placeholder: "Vui lòng nhập số tiền rút",
        success: "Rút tiền thành công!",
        recommend: {
            title: "Giới thiệu chúng tôi cho bạn bè của bạn",
            referralCode: "Mã giới thiệu của tôi",
            download: "Tải về mã QR đại lý",
            copyLink: "Sao chép liên kết đại lý",
            promotionNumber: "Số lượng thành viên giới thiệu",
            member: "Chi tiết thành viên",
            id: "ID thành viên",
            name: "Tên thành viên",
            createTime: "Thời gian đăng ký",
            lastLoginTime: "Thời gian hoạt động gần đây",
        }
    },
    flow: {
        title: "Tổng số tiền tiêu",
        residue: "Còn lại có thể sử dụng",
        thisWeek: "Tiêu tháng này",
        today: "Tiêu hôm nay",
        placeholderTransactionNo: "Vui lòng nhập số dòng tiền",
        placeholderWalletLogType: "Loại tiêu",
        search: "Tìm kiếm",
        TypeName: "Loại giao dịch",
        CreateTime: "Thời gian giao dịch",
        VariableState: "Trạng thái",
        Money: "Số tiền biến động",
        OldMoney: "Số tiền trước khi biến động",
        OrderNo: "Mã đơn hàng hệ thống",
        TransactionNo: "Mã giao dịch",
        Rem: "Ghi chú",
        Balance: "Số dư",
        Integral: "Điểm tích lũy"
    },
    comment: {
        title: "Vui lòng chia sẻ sự hài lòng chung của bạn đối với dịch vụ vận chuyển/giao hàng",
        prompt: "Viết nhận xét của bạn",
        placeholder: "Vui lòng nhập nội dung",
        veryPoor: "Rất kém",
        disappointed: "Thất vọng",
        average: "Trung bình",
        satisfied: "Hài lòng",
        verySatisfied: "Rất hài lòng",
        callback: "Cảm ơn sự đánh giá của bạn",
        mainTitle: "Tại sao chọn chúng tôi?",
        subTitle: "Xem nhận xét từ đối tác khác",
        reply: "Trả lời từ nhà cung cấp"
    },
    cashier: {
        paymentMethod: 'Phương thức thanh toán',
        payNow: 'Thanh toán ngay',
        cancel: 'Hủy bỏ',
        confirmBalance: 'Bạn có muốn sử dụng số dư để thanh toán {Receivables} đồng không?',
        prompt: "Chú ý",
        confirm: "Xác nhận",
        cancelDelete: "Thanh toán đã được hủy",
        successPayment: "Thanh toán thành công",
        warning: "Vui lòng chọn phương thức thanh toán"
    },
    footer: {
        domesticConsolidation: 'Quy trình gom hàng nội địa',
        domesticDeliveryService: 'Dịch vụ giao hàng nội địa Hồng Kông',
        domesticStorePickup: 'Lấy hàng tại cửa hàng nội địa Hồng Kông',
        more: 'Xem thêm...',
        overseasConsolidation: 'Quy trình gom hàng quốc tế',
        overseasDeliveryService: 'Dịch vụ giao hàng quốc tế',
        overseasStorePickup: 'Lấy hàng tại cửa hàng quốc tế',
        noviceTeach: 'Hướng dẫn cho người mới',
        frequentlyQuestion: 'Câu hỏi thường gặp',
        freightCalculation: 'Tính toán cước phí vận chuyển',
        earnPoints: 'Trung tâm trợ giúp',
        cashRoll: 'Cách nhận ưu đãi',
        payment: 'Cách thanh toán',
        companyProfile: 'Về chúng tôi',
        declarationProtection: 'Tuyên bố bảo vệ quyền riêng tư',
        privacyStatement: 'Tuyên bố quyền riêng tư của ứng dụng',
        disclaimers: 'Điều khoản sử dụng và miễn trừ trách nhiệm',
        acceptCarriage: 'Chấp nhận hợp đồng vận chuyển',
        contactUs: 'Liên hệ với chúng tôi',
        filings: '@ 2023 Bản quyền thuộc về 2023 战北. Đã đăng ký bản quyền'
    },
    download: {
        ios: 'Tải về iOS',
        android: 'Tải về Android'
    },
    mergerCard: {
        Channel: "Kênh",
        placeholderChannel: "Vui lòng chọn kênh",
        popover: {
            CarryWeight: "Trọng lượng tính cước",
            FirstWeight: "Trọng lượng đầu tiên",
            FirstPrice: "Giá trọng lượng đầu tiên",
            FirstWeightSurcharge: "Phí phụ trọng lượng đầu tiên",
            LastWeight: "Trọng lượng phụ",
            LastWeightUnitPrice: "Giá trọng lượng phụ",
            LastWeightSurcharge: "Phí phụ trọng lượng phụ"
        },
        VolumeWeight: "Trọng lượng thể tích",
        totalVolumeWeight: "Tổng khối lượng Trọng lượng",
        yes: "Có",
        no: "Không",
        validateIntegral: "Số điểm vượt quá số điểm hiện có",
        preferential: "Ưu đãi được",
        servicePrice: "Phí dịch vụ",
        Discount: "Tỷ lệ giảm giá",
        InsuredCommission: "Phí thủ tục bảo hiểm",
        AgenServiceFree: "Phí dịch vụ điểm tự lấy",
        CouponAmount: "Số tiền ưu đãi từ mã giảm giá",
        IntegralAmount: "Số điểm sử dụng",
        AddedServicesFee: "Phí dịch vụ bổ sung",
        TaxCommission: "Phí thủ tục thuế",
        CollectionCommission: "Phí thủ tục thu hồi",
        DestinationCharges: "Phí đích",
        AccruedCharge: "Tổng chi phí",
        DeclareAccount: "Số lượng đã được khai báo",
        Size: "Kích thước",
        Weight: "Trọng lượng",
        step1: 'Thông tin gói hàng',
        step2: 'Phân phối đơn hàng',
        step3: 'Thông tin đơn hàng',
        step4: 'Thanh toán đặt hàng',
        ServiceType: 'Loại dịch vụ',
        placeholderServiceType: 'Chọn loại dịch vụ',
        Code: 'Mã vận đơn',
        Amount: 'Số lượng',
        GoodName: 'Tên hàng hóa',
        GoodsTypeName: 'Loại hàng hóa',
        Surcharge: 'Phụ phí (VND)',
        totalCount: 'Tổng số lượng',
        totalWeight: 'Tổng trọng lượng',
        totalSurcharge: 'Tổng phụ phí',
        placeholderGoodsTypeName: 'Chọn loại hàng hóa',
        Carrier: 'Nhà vận chuyển',
        placeholderCarrier: 'Chọn nhà vận chuyển',
        Timer: 'Thời gian',
        placeholderTimer: 'Chọn thời gian',
        AddedService: 'Dịch vụ bổ sung',
        placeholderAddedService: 'Chọn dịch vụ bổ sung',
        Integral: 'Số điểm',
        available: 'Có sẵn',
        Coupon: 'Mã giảm giá',
        placeholderCoupon: 'Chọn mã giảm giá',
        ClientAddress: 'Giao hàng đến',
        placeholderClientAddress: 'Chọn địa chỉ',
        CollectionAmount: 'Số tiền thu hồi',
        InsuredAmount: 'Số tiền bảo hiểm',
        IsTax: 'Có bao gồm thuế',
        placeholderIsTax: 'Chọn có hoặc không',
        DeclareName: 'Người khai báo',
        DeclarePhone: 'Điện thoại khai báo',
        DeclareAddress: 'Địa chỉ khai báo',
        DeclareIdCard: 'Mã số khai báo',
        DeclarePostCode: 'Mã bưu điện khai báo',
        DeclareEmail: 'Email khai báo',
        DeclareTelephone: 'Số điện thoại khai báo',
        DeclareMoney: 'Tổng số tiền khai báo',
        DeclarePrice: 'Giá khai báo',
        GoodsName: 'Tên hàng hóa',
        GoodsName_En: 'Tên hàng hóa tiếng Anh',
        feeDetail: 'Chi tiết phí',
        weight: 'Trọng lượng',
        Freight: 'Cước phí',
        FreightDetail: 'Chi tiết tính cước phí',
        CalculateFeeSurcharge: 'Phụ phí gói hàng',
        CalculateFeeAddressSurcharge: 'Phụ phí địa chỉ',
        CalculateFeeStorageCharges: 'Phí lưu trữ',
        CalculateFeeLengthExceed: 'Phí vượt quá chiều dài',
        packageCount: 'Số lượng gói hàng',
        CalculateFeeWeightExceed: 'Phí vượt quá trọng lượng',
        paymentMethod: 'Phương thức thanh toán',
        back: 'Trở lại',
        next: 'Tiếp theo',
        settlement: 'Thanh toán',
        placeholderGoodsName_En: 'Nhập tên hàng hóa tiếng Anh',
        placeholderGoodsName: 'Nhập tên hàng hóa',
        validatePostalCode: 'Vui lòng nhập mã bưu điện Trung Quốc hợp lệ',
        validateLandline: 'Vui lòng nhập số điện thoại cố định hợp lệ của Trung Quốc',
        validateEmail: 'Vui lòng nhập địa chỉ email hợp lệ',
        validateMobile: 'Vui lòng nhập số điện thoại di động hợp lệ của Trung Quốc',
        validateCreditCode: 'Vui lòng nhập số thống kê hoặc số căn cước dân dân',
        paySuccess: 'Thanh toán thành công!!!',
        payInfo: 'Thanh toán đã bị hủy !!!, Vui lòng thanh toán trong danh sách đơn hàng',
        orderSuccess: 'Đặt hàng thành công! Đang gọi thanh toán...'
    },
    route: {
        index: 'Trang chủ',
        member: 'Trung tâm thành viên',
        personal: 'Tổng quan trung tâm cá nhân',
        account: 'Cài đặt thông tin cá nhân',
        flow: 'Lịch sử giao dịch',
        coupon: 'Mã giảm giá của tôi',
        notify: 'Cài đặt thông báo',
        ConsolidatedDomestic: 'Gom hàng',
        OrderDomestic: 'Đơn hàng của tôi',
        AddressDomestic: 'Địa chỉ nhận hàng',
        declare: 'Khai báo hàng hóa',
        claimDomestic: 'Yêu cầu thông báo',
        chargeIntroduce: 'Giới thiệu phí',
        service: 'Phí và dịch vụ',
        agent: "Trung tâm đại lý",
        recommend: "Giới thiệu bạn bè",
        help: 'Trung tâm trợ giúp',
        Consolidation: 'Quy định gom hàng',
        problem: 'Câu hỏi thường gặp',
        contraband: 'Hàng hóa cấm vận',
        VolumeConversion: 'Tính toán thể tích',
        ShippingCalculation: 'Tính toán phí vận chuyển',
        teach: 'Hướng dẫn cho người mới',
        lostHandle: 'Xử lý hàng mất',
        precautions: 'Lưu ý',
        message: 'Tin nhắn mới nhất',
        logistics: 'Tra cứu vận đơn',
        pickupPoint: 'Danh sách điểm nhận hàng',
        news: 'Chi tiết thông báo',
        procedureDomestic: 'Gom hàng trong nước',
        Login: 'Đăng nhập',
        Register: 'Đăng ký',
        added: 'Loại dịch vụ bổ sung',
        addedList: 'Danh sách dịch vụ bổ sung',
        shopeeShop: 'Quản lý cửa hàng Shopee',
        shopeeOrder: 'Quản lý đơn hàng Shopee',
        immediate: "Đến mảnh là xong.",
        upload: "Nhập ngang hàng"
    },
    upload: {
        save: "Lưu",
        selectFile: "Chọn tệp nhập",
        downloadTemplate: "Tải tệp mẫu",
        delete: "Xóa",
        loadingText: "Đang tải...",
        errorMsg: "Thông báo lỗi dữ liệu",
        mainBillCode: "Số đơn hàng",
        carrierBillCode: "Số đơn hàng của nhà vận chuyển",
        subbillNumber: "Số đơn phụ",
        parcelCode: "Số gói hàng",
        skuCode: "SKU",
        clientRem: "Ghi chú khách hàng",
        rem: "Ghi chú đơn hàng",
        goodsTypeId: "Loại hàng hóa",
        timerId: "Thời gian giao hàng",
        carrierId: "Nhà vận chuyển",
        goodsCount: "Số lượng hàng hóa",
        goodsName: "Tên hàng hóa",
        goodsNameEn: "Tên tiếng Anh",
        destinationId: "Điểm đến",
        province: "Tỉnh",
        city: "Thành phố",
        area: "Khu vực",
        collectionAmount: "Số tiền thu hộ",
        insuredAmount: "Số tiền báo giá",
        weight: "Trọng lượng thực tế",
        isTax: "Có bao gồm thuế không",
        agentPointId: "Điểm nhận hàng",
        idCard: "Số chứng minh nhân dân",
        postNo: "Mã bưu chính",
        receiveName: "Tên người nhận",
        receiveAddress: "Địa chỉ người nhận",
        receivePhoneNo: "Điện thoại di động người nhận",
        receiveTel: "Số điện thoại người nhận",
        email: "Email",
        declareName: "Tên người khai báo",
        declareAddress: "Địa chỉ người khai báo",
        declareTel: "Điện thoại người khai báo",
        declarePhoneNo: "Điện thoại di động người khai báo",
        declareIdCard: "ID khai báo",
        declarePrice: "Đơn giá khai báo",
        declareCount: "Số lượng khai báo",
        declareValue: "Giá trị khai báo",
        length: "Chiều dài",
        width: "Chiều rộng",
        height: "Chiều cao",
        createTime: "Thời gian đặt hàng",
        dialogTitle: "Thông báo",
        warehouseLabel: "Kho hàng",
        submitButton: "Xác nhận gửi",
        placeholderWarehouse: "Vui lòng chọn kho",
        templateName: "Mẫu{0}.xlsx",
        downloadError: "Tải xuống thất bại: {0}",
        downloadSuccess: "Tải xuống thành công",
        exportSuccess: "Nhập khẩu thành công!",
        validateError: "Có dữ liệu bất thường. Vui lòng kiểm tra và gửi lại.",
        prompt: "Thông báo",
        message: "Hành động này sẽ xóa dữ liệu của đơn hàng số ({0}). Bạn có muốn tiếp tục không?",
        confirm: "Xác nhận",
        cancel: "Hủy",
        deleteSuccess: "Xóa thành công!"
    },
    login: {
        title: 'Logistics của Zhanbei',
        subTitle1: 'Làm cho vận hành đơn giản hơn',
        subTitle2: 'Đang cần hàng, tìm Zhanbei',
        subTitle3: 'Đối tác của hơn 3000 doanh nghiệp',
        subTitle4: 'Làm mới tốc độ của tôi để đáp ứng sự hài lòng của bạn',
        login: 'Đăng nhập thành viên',
        account: 'Tên tài khoản',
        password: 'Mật khẩu',
        register: 'Đăng ký',
        forgetPassword: 'Quên mật khẩu',
        submit: 'Đăng nhập',
        placeholderAccount: 'Nhập tên tài khoản',
        placeholderPassword: 'Nhập mật khẩu'
    },
    register: {
        title: 'Logistics của Zhanbei',
        subTitle1: 'Làm cho vận hành đơn giản hơn',
        subTitle2: 'Đang cần hàng, tìm Zhanbei',
        subTitle3: 'Đối tác của hơn 3000 doanh nghiệp',
        subTitle4: 'Làm mới tốc độ của tôi để đáp ứng sự hài lòng của bạn',
        item: 'Trở thành thành viên của {0}',
        account: 'Tên tài khoản',
        password: 'Mật khẩu',
        phone: 'Số điện thoại',
        aws: 'Số WW của Taobao, Tài khoản cửa hàng Amazon',
        notice: 'Đồng ý nhận tất cả email từ trang web này',
        term: 'Chấp nhận',
        termTitle1: '[Dịch vụ và điều khoản]',
        termTitle2: '[Hợp đồng vận chuyển]',
        termTitle3: '[Lưu ý]',
        termTitle4: '[Xử lý hàng hỏng]',
        termUse: 'của Zhanbei',
        hasAccount: 'Đã có tài khoản, đăng nhập ngay',
        submit: 'Đăng ký',
        placeholderAccount: 'Nhập tên tài khoản',
        placeholderPassword: 'Nhập mật khẩu',
        placeholderPhone: 'Nhập số điện thoại',
        mainlandPhone: 'Vui lòng nhập số điện thoại 11 số',
        twPhone: 'Vui lòng nhập số điện thoại 8 số',
        placeholderTerm: 'Vui lòng đọc và chấp nhận điều khoản!',
        success: 'Đăng ký thành công!',
        agent: "ID người dùng proxy",
        recommender: "Giới thiệu ID bạn bè",
        Email: "hộp thư",
        Prompt: "Dịch vụ và Hiệp ước"
    },
    message: {
        couponsState: {
            upToDate: 'Thông báo mới nhất',
            preferential: 'Chương trình ưu đãi',
        },
        releaseTime: "Thời gian phát hành",
        date: "Ngày",
        content: "Nội dung tiêu đề",
        activeTime: 'Thời gian hoạt động'
    },
    contraband:{
        title:"Hàng hóa cấm vận"
    },
    losthandle:{
        title:"Xử lý hàng mất"
    },
    problem:{
        domestic:"Gom hàng nội địa",
        foreign:"Gom hàng ngoại địa",
    },
    shippingcalculation:{
        title:"Tính phí vận chuyển",
        destination:"Đích đến",
        goodtype:"Loại hàng",
        volume:"Thể tích (đơn vị)",
        length:"Nhập chiều dài hàng",
        width:"Nhập chiều rộng hàng",
        height:"Nhập chiều cao hàng",
        weight:"Nhập trọng lượng hàng",
        submit:"Tính toán",
        result:"Kết quả tính phí vận chuyển",
        warehouseName:"Kho hàng",
        dialogItems: {
            goodsType: 'Loại hàng',
            timer: 'Thời gian',
            carrier: 'Nhà vận chuyển',
            fee: 'Giá (RMB)',
            lengthExceed: 'Phí vượt chiều dài (RMB)',
            carryWeight: 'Trọng lượng tính toán (KG)'
        },
        rules: {
            selectWarehouse: 'Vui lòng chọn kho hàng',
            selectDestination: 'Vui lòng chọn điểm đến',
            selectGoodType: 'Vui lòng chọn loại hàng',
            enterLength: 'Vui lòng nhập chiều dài hàng',
            enterWidth: 'Vui lòng nhập chiều rộng hàng',
            enterHeight: 'Vui lòng nhập chiều cao hàng',
            enterWeight: 'Vui lòng nhập trọng lượng hàng'
        },
    },
    teach:{
        title:"Đăng ký/Tạo tài khoản",
        title1: "Hướng dẫn video",
        title2: "Hướng dẫn hình ảnh",
        loading: "Đang tải"
    },
    volume:{
        title:"Tính toán thể tích",
        length:"Chiều dài",
        width:"Chiều rộng",
        height:"Chiều cao",
        fillLength: 'Vui lòng nhập chiều dài',
        fillWidth: 'Vui lòng nhập chiều rộng',
        fillHeight: 'Vui lòng nhập chiều cao',
        lengthplaceholder:"Nhập chiều dài hàng",
        widthplaceholder:"Nhập chiều rộng hàng",
        heightplaceholder:"Nhập chiều cao hàng",
        compute:"Tính toán",
        result:"Kết quả tính toán",
        notice1: '01: Phụ thu phí khu vực hoặc phí khu vực hẻo lánh, vui lòng nhấp vào từng nhà cung cấp dịch vụ giao hàng tại Hồng Kông để biết chi phí.',
        notice2: '02: Số lượng hàng hóa gom vận không có hạn chế.',
        notice3: '03: Các cạnh của hàng gửi không được vượt quá 200CM, tổng trọng lượng không được vượt quá 300KG.',
        notice4: '04: Nếu trọng lượng của một món hàng vượt quá 45KG, do khó khăn trong việc vận chuyển, các công ty giao hàng có thể thu thêm phí từ 50 đến 300 RMB tuỳ thuộc vào trọng lượng, trọng lượng càng nặng, phí vận chuyển càng cao.',
        notice5: '05: Nếu các cạnh của một món hàng vượt quá 150CM, do hạn chế của phương tiện vận chuyển, các công ty giao hàng có thể thu thêm phí từ 50 đến 300 RMB tuỳ thuộc vào kích thước, càng dài, phí càng cao.',
    },
    footerBox: {
        title1: 'Khám phá vô số khả năng',
        title2: 'Tải ứng dụng của chúng tôi chỉ với một cú nhấp chuột',
        title3: 'Làm cho việc gom hàng trở nên dễ dàng hơn!',
        installIOS: 'Tải ứng dụng iOS',
        installAndroid: 'Tải ứng dụng Android',
        features1: 'Quản lý hàng hóa  Quản lý đơn hàng  Thông báo mới nhất  Liên lạc trực tiếp',
        features2: 'Tìm kiếm điểm tự lấy hàng trên bản đồ',
        features3: 'Theo dõi vận chuyển hàng hóa theo thời gian thực',
        // Các chức năng khác...
    },
    noticelayout:{
        title:"Quy định gom hàng"
    },
    layout: {
        consolidation: {
            tutorial: 'Hướng dẫn cho người mới',
            shippingCalculation: 'Tính toán cước phí vận chuyển',
            volumeConversion: 'Chuyển đổi thể tích',
            precautions: 'Lưu ý',
            faq: 'Câu hỏi thường gặp',
            prohibitedItems: 'Hàng hóa cấm vận và lưu trữ'
        },
        service: {
            aboutUs: 'Về chúng tôi',
            privacyStatement: 'Tuyên bố về quyền riêng tư',
            termsAndDisclaimer: 'Điều khoản và miễn trừ trách nhiệm',
            carriageAgreement: 'Thỏa thuận vận chuyển',
            contactUs: 'Liên hệ với chúng tôi'
        },
        member: {
            overview: "Tổng quan thông tin thành viên",
            accountSettings: "Cài đặt tài khoản",
            flow: "Dòng tiền trong ví",
            AgencyPromotion: "Đại lý và khuyến mãi",
            AgentCenter: "Trung tâm đại lý",
            recommend: "Giới thiệu bạn bè",
            myCoupons: "Mã giảm giá của tôi",
            privateMessages: "Tin nhắn riêng",
            myDomesticWarehouse: "Quản lý gom hàng",
            pickupPoint: "Cung cấp điểm tự lấy hàng",
            domesticWarehouseAddress: "Địa chỉ nhận hàng của tôi",
            claimGoods: "Dự báo bưu kiện",
            mergeGoods: "Gom hàng",
            orderList: "Danh sách đơn hàng",
            myOverseasWarehouse: "Kho hàng quốc tế của tôi",
            overseasWarehouseAddress: "Địa chỉ kho hàng quốc tế",
            goodsDeclaration: "Khai báo hàng hóa",
            mergeGoodsOverseas: "Gom hàng",
            myOrdersOverseas: "Đơn hàng của tôi",
            notificationSettings: "Cài đặt thông báo",
            addedType: "Loại dịch vụ bổ sung",
            addedList: "Danh sách dịch vụ bổ sung",
            AddedService: "Dịch vụ bổ sung",
            shopee: 'Liên quan đến Shopee',
            shopeeShop: 'Quản lý cửa hàng',
            shopeeOrder: 'Quản lý đơn hàng'
        }
    },
    service:{
        title:"Phí và Dịch vụ",
        aboutUs: 'Về chúng tôi',
        privacyStatement: 'Tuyên bố về quyền riêng tư',
        termsAndDisclaimer: 'Điều khoản và miễn trừ trách nhiệm',
        carriageAgreement: 'Thỏa thuận vận chuyển',
        contactUs: 'Liên hệ với chúng tôi'
    },
    rich:{
        title:"Trung tâm Trợ giúp"
    },
    advantagebox:{
        title:"Lợi thế của chúng tôi"
    },
    corebox:{
        title:"Dịch vụ cốt lõi",
        title1: "Hiệu quả và an toàn",
        notices1: "Chúng tôi cam kết tối ưu hóa quy trình vận chuyển hàng hóa, cung cấp phản hồi nhanh chóng, tuân thủ chặt chẽ các biện pháp an toàn, sử dụng tiêu chuẩn đóng gói, xếp dỡ cao và cung cấp dịch vụ bảo hiểm hàng hóa toàn diện, đảm bảo an toàn và đúng hẹn cho hàng hóa trong toàn bộ chuỗi cung ứng.",
        title2: "Cam kết chất lượng",
        notices2: "Chúng tôi kiên định với tiêu chuẩn dịch vụ cao cấp để tạo ra giá trị cho khách hàng, sử dụng hệ thống thông tin tiên tiến và đội ngũ chuyên gia vận tải chuyên nghiệp, theo dõi tình trạng hàng hóa theo thời gian thực, đảm bảo tính chính xác và minh bạch của thông tin vận chuyển.",
        title3: "Cá nhân hóa và bảo vệ môi trường",
        notices3: "Chúng tôi cung cấp các giải pháp đóng gói cá nhân hóa, chuyên nghiệp và bảo vệ môi trường dựa trên các đặc điểm ngành và yêu cầu của khách hàng, cung cấp nhiều tùy chọn bảo hiểm vận chuyển và các dịch vụ gia tăng khác. Chúng tôi tích cực thực hiện trách nhiệm xã hội, thúc đẩy triết lý vận chuyển xanh và nỗ lực giảm thiểu ô nhiễm môi trường và tiêu thụ năng lượng trong quá trình vận chuyển.",
        title4: "Cải tiến liên tục",
        notices4: "Chúng tôi luôn đi đầu trong các xu hướng phát triển ngành và không ngừng áp dụng công nghệ và mô hình quản lý mới trong lĩnh vực vận chuyển, liên tục tối ưu hóa hệ thống dịch vụ, cam kết cung cấp trải nghiệm dịch vụ vận chuyển quốc tế chất lượng và tiên tiến cho khách hàng."
    },
    logistics:{
        search:"Tìm kiếm",
        records:"Lịch sử",
        clearrecord:"Xóa lịch sử",
        placeholder: "Nhập số vận đơn"
    },
    account:{
        passwordChangeSuccess: 'Đổi mật khẩu thành công, vui lòng đăng nhập lại',
        enterCorrectPhoneNumber: 'Vui lòng nhập số điện thoại đúng',
        copySuccess: 'Sao chép thành công!',
        copyTextFailed: 'Sao chép văn bản thất bại',
        confirmDelete: 'Thao tác này sẽ xóa tài khoản vĩnh viễn, bạn có muốn tiếp tục không?',
        prompt: 'Chú ý',
        confirm: 'Xác nhận',
        enterPassword: 'Vui lòng nhập mật khẩu',
        deleteAccount: 'Xóa tài khoản',
        passwordNotEmpty: 'Mật khẩu không được để trống',
        deleteRequestSuccess: 'Yêu cầu xóa tài khoản đã được gửi thành công! Đăng nhập trước {time} được xem là hủy bỏ',
        userChangeSuccess: 'Thay đổi thông tin người dùng thành công!',
        editAvatar: "Chỉnh sửa ảnh đại diện",
        filters: {
            female: 'Nữ',
            male: 'Nam',
            secret: 'Bí mật',
            unlimited: "Không giới hạn",
        },
        rules: {
            enterOldPassword: 'Vui lòng nhập mật khẩu cũ',
            passwordLength: 'Độ dài mật khẩu phải ít nhất 6 ký tự',
            enterNewPassword: 'Vui lòng nhập mật khẩu mới',
            passwordMismatch: 'Mật khẩu nhập lại không khớp',
            enterUserName: 'Vui lòng nhập tên người dùng',
            selectGender: 'Vui lòng chọn giới tính',
            enterEmail: 'Vui lòng nhập địa chỉ email',
            enterPhoneNumber: 'Vui lòng nhập số điện thoại'
        },
        listData: {
            memberNumber: 'Số thành viên',
            taobaoAccount: 'Tài khoản Taobao',
            userName: 'Tên người dùng',
            gender: 'Giới tính',
            email: 'Email',
            loginPassword: 'Mật khẩu đăng nhập',
            phoneNumber: 'Số điện thoại',
            avatar: 'Ảnh đại diện'
        },
        title:"Cài đặt thông tin cá nhân",
        accountsecurity:"Bảo mật tài khoản",
        password:"Thay đổi mật khẩu",
        mailbox:"Xác nhận email",
        modifyemail:"Sửa đổi email",
        cancellation:"Hủy tài khoản",
        editaccount:"Chỉnh sửa thông tin tài khoản",
        name:"Tên người dùng",
        sex:"Giới tính",
        pleaseselect:"Vui lòng chọn",
        secrecy:"Bí mật",
        male:"Nam",
        female:"Nữ",
        unlimited: "Không giới hạn",
        email:"Email",
        phone:"Điện thoại",
        phonenumber:"Số điện thoại",
        confirmupdate:"Xác nhận chỉnh sửa",
        cancel:"Hủy",
        oldpassword:"Mật khẩu cũ",
        newpassword:"Mật khẩu mới",
        confirmpassword:"Xác nhận mật khẩu",
    },
    address:{
        common: {
            prompt: 'Chú ý',
            confirm: 'Xác nhận',
            cancel: 'Hủy',
        },
        AddressSelect: {
            title: "Thông tin người nhận",
            add: "Thêm địa chỉ nhận hàng",
            expand: "Mở rộng",
            edit: "Chỉnh sửa",
            delete: "Xóa",
            retract: "Thu gọn địa chỉ"
        },
        selectServiceType: 'Vui lòng chọn loại dịch vụ',
        confirmDelete: 'Thao tác này sẽ xóa vĩnh viễn địa chỉ này, bạn có muốn tiếp tục không?',
        deleteSuccess: 'Xóa thành công!',
        addSuccess: 'Thêm địa chỉ thành công!',
        editSuccess: 'Chỉnh sửa địa chỉ thành công!',
        areas: "Khu vực",
        SetDefaultAddress: 'Đặt làm địa chỉ mặc định',
        defaultSuccess: "Thiết lập thành công",
        TypeText: "Loại",
        editAddress: 'Chỉnh sửa địa chỉ',
        addAddress: 'Thêm địa chỉ',
        selectagent: 'Điểm tự lấy',
        selectCountry: 'Vui lòng chọn quốc gia',
        selectProvince: 'Vui lòng chọn tỉnh thành',
        selectCity: 'Vui lòng chọn thành phố',
        selectDistrict: 'Vui lòng chọn quận huyện',
        enterRecipientInfo: 'Vui lòng nhập thông tin người nhận',
        enterContactPhone: 'Vui lòng nhập số điện thoại liên hệ',
        enterDetailedAddress: 'Vui lòng nhập địa chỉ cụ thể',
        IsDefaultAddress: "Địa chỉ mặc định",
        selectAgentPoint: 'Vui lòng chọn địa chỉ điểm tự lấy',
        pickup: "Tự lấy",
        dispatch: "Phân phối",
        serviceType: "Loại dịch vụ",
        title:"Danh sách địa chỉ nhận hàng của tôi",
        all:"Tất cả địa chỉ",
        recipientname:"Tên người nhận",
        recipientphone:"Số điện thoại người nhận",
        recipientaddress:"Địa chỉ chi tiết",
        destinationname:"Đích đến",
        operate:"Thao tác",
        edit:"Chỉnh sửa",
        delete:"Xóa",
        add:"Thêm",
        disposition:"Quản lý cấu hình",
        role:"Quản lý vai trò",
        nodistrict:"Không phải quận huyện",
        district:"Quận huyện",
        destination:"Đích đến",
        selectdestination:"Vui lòng chọn đích đến",
        province:"Tỉnh thành",
        selectprovince:"Tỉnh",
        city:"Thành phố",
        selectcity:"Thành phố",
        area:"Khu vực",
        selectarea:"Khu vực",
        cancel:"Hủy",
        confirmupdate:"Xác nhận chỉnh sửa",
        Confirmaddition:"Xác nhận thêm mới",
        Dispatch: "Phân phối",
        SelfPickup: "Tự lấy"
    },
    singlecard:{
        code:"Nhập số vận đơn",
        goodsname:"Nhập tên hàng hóa",
        goodstype:"Loại hàng hóa",
        account:"Số lượng hàng hóa",
        price:"Đơn giá hàng hóa",
        totalprice:"Tổng giá hàng hóa",
        clientrem:"Ghi chú",
        warehouseNumber: 'Số nhà kho',
    },
    apply:{
        warehouseNumber: 'Số nhà kho',
        enterExpressNumber: 'Nhập số vận đơn',
        enterGoodsName: 'Nhập tên hàng hóa',
        selectGoodsType: 'Chọn loại hàng hóa',
        enterGoodsQuantity: 'Nhập số lượng hàng hóa',
        enterUnitPrice: 'Nhập đơn giá hàng hóa',
        enterTotalPrice: 'Nhập tổng giá hàng hóa',
        enterInformation: 'Nhập thông tin',
        fillInCompleteInformation: 'Vui lòng điền đầy đủ thông tin',
        reportSuccess: 'Báo cáo thành công!',
        noticeTitle: 'Lời nhắc',
        notice1: 'Vận đơn dự báo là số vận đơn được xác nhận bởi trang web mua sắm sau khi đơn hàng của bạn được xác nhận. Việc báo cáo trước cho chúng tôi sẽ cải thiện quản lý gói hàng nhập và xuất kho, giúp xử lý gói hàng của bạn một cách hiệu quả hơn.',
        notice2: '【Hàng hóa thông thường】Không có nhãn, không có điện, không có sản phẩm điện tử và phụ kiện và loại quần áo....',
        notice3: '【Kênh Thực Phẩm】Kênh thực phẩm không thể chạy chất lỏng tinh khiết, bột tinh khiết, pin, động cơ, thịt, trứng, vv.',
        notice4: '【Đặc biệt A】Máy ghi hình xe hơi, máy nghe nhạc, đĩa truyện, DVD, DV, USB MP3/45 đồng hồ, pin động cơ, đồ chơi điều khiển từ xa, đồ chơi ô tô điện, máy trò chuyện, loa,.... lăng kính ánh sáng, lens mắt, trà, mỹ phẩm, thực phẩm chất lỏng, bột,....',
        notice5: '【Đặc biệt C】Thuốc, máy tính bảng, điện thoại,...',
        title:"Yêu cầu dự báo",
        individualforecast:"Dự báo từng mục",
        batchforecast:"Dự báo theo lô",
        code:"Số vận đơn",
        goodsname:"Tên hàng hóa",
        goodstype:"Loại hàng hóa",
        account:"Số lượng hàng hóa",
        price:"Đơn giá hàng hóa",
        totalprice:"Tổng giá hàng hóa",
        clientrem:"Ghi chú",
        add:"Thêm",
        remove:"Xóa",
        submit:"Gửi"
    },
    added: {
        type: "Đối tượng dịch vụ",
        name: "Tên dịch vụ",
        search: "Tìm kiếm",
        TypeTxt: "Loại dịch vụ",
        Price: "Phí dịch vụ",
        Rem: "Chú thích dịch vụ",
        order: "Đơn hàng",
        package: "Gói hàng",
        PayState: "Trạng thái thanh toán",
        PayTime: "Thời gian thanh toán",
        IsHandle: "Trạng thái xử lý",
        HandleTime: "Thời gian xử lý",
        CreateTime: "Thời gian tạo",
        PackageType: "Loại đơn hàng",
        paid: "Đã thanh toán",
        unpaid: "Chưa thanh toán",
        handle: "Đã xử lý",
        Unprocessed: "Chưa xử lý",
        Code: "Mã số",
        placeholderCode: "Mã đơn hàng/Gói hàng",
        OrderId: "ID",
        orderCode: "Mã đơn hàng",
        billCode: "Mã số vận đơn"
    },
    goodcard:{
        goodsname:"Tên gói hàng",
        weight:"Trọng lượng (KG)",
        code:"Mã số vận đơn",
        volumeweight:"Trọng lượng theo thể tích (KG)",
        size:"Kích thước",
        day: "Ngày",
        remaining: "Còn lại có thể miễn lưu",
        ProblemTypeName: "Loại vấn đề",
        Rem: "Chú thích",
        HandleStateName: "Trạng thái xử lý",
        HandleRem: "Chú thích xử lý",
        HandleTime: "Thời gian xử lý",
        GoodsTypeName: "Loại hàng hóa",
        Account: "Số lượng"
    },
    domestic:{
        showImage: "Xem ảnh",
        confirmDelete: 'Thao tác này sẽ xóa vĩnh viễn gói hàng ({goodsName}), bạn có muốn tiếp tục không?',
        placeholder: "Vui lòng nhập mã vận đơn, nhiều mã cách nhau bằng dấu cách",
        search: "Tìm kiếm",
        CreateTime: "Thời gian tạo",
        prompt: 'Lời nhắc',
        confirm: 'Xác nhận',
        deleteSuccess: 'Xóa thành công',
        cancelDelete: 'Đã hủy xóa',
        updateSuccess: 'Chỉnh sửa thành công!',
        selectMergePackages: 'Vui lòng chọn các gói hàng để gộp đơn trước!',
        diffWarehouse: 'Không thể áp dụng cho các kho hàng khác nhau!',
        unstoredGoods: 'Không thể áp dụng cho hàng hóa chưa nhập kho!',
        UpdateRules: {
            GoodsName: {
                required: 'Vui lòng nhập tên hàng hóa',
            },
            GoodsTypeName: {
                required: 'Vui lòng chọn loại hàng hóa',
            },
            Account: {
                required: 'Vui lòng nhập số lượng hàng hóa',
            },
            Price: {
                required: 'Vui lòng nhập giá hàng hóa',
            },
        },
        all:"Tất cả hàng hóa",
        goodsinfo:"Thông tin hàng hóa",
        status:"Trạng thái kho hàng",
        arrived:"Đã đến kho",
        unarrive:"Chưa đến kho",
        waitPayment: "Đặt hàng",
        claim: 'Chờ nhận',
        forecast: 'Nhận hàng',
        goodClaim: 'Nhận hàng',
        account:"Số lượng (cái)",
        warehousename:"Tên kho hàng",
        operate:"Hoạt động",
        edit:"Chỉnh sửa",
        delete:"Xóa",
        selection:"Đã chọn",
        addup:"Tổng (KG)",
        overlength:"Vượt quá chiều dài (M)",
        combine:"Gộp đơn",
        disposition:"Quản lý cấu hình",
        role:"Quản lý vai trò",
        combinefleight:"Gộp vận chuyển",
        editinfo:"Chỉnh sửa thông tin",
        goodsname:"Tên gói hàng",
        goodstype:"Loại hàng hóa",
        selectgoodstype:"Vui lòng chọn loại hàng hóa",
        goodsaccount:"Số lượng",
        goodsprice:"Giá",
        clientrem:"Ghi chú",
        confirmupdate:"Xác nhận chỉnh sửa",
        cancel:"Hủy bỏ",
    },
    consolidation: {
        noticeTitle: 'Lưu ý',
    },
    coupon:{
        effectivetime:"Thời gian hiệu lực",
        expiredtime:"Thời gian hết hạn",
        use:"Sử dụng ngay",
        pointscashroll:"Phiếu tiền mặt và điểm",
        cashroll:"Phiếu tiền mặt",
        pointexchange:"Đổi điểm",
        exchange:"Đổi",
        discountcoupon:"Phiếu giảm giá",
        periodOfValidity: "Thời hạn hiệu lực",
        to: "đến",
        integral: "điểm tích lũy",
        exchangeSuccess: "thành công trao đổi",
        title: 'Sử dụng điểm để đổi phiếu giảm giá, bạn có muốn tiếp tục không?',
        notice: "Hợp lệ trong vòng {0} ngày kể từ ngày trao đổi",
        minPrice: "Giá sử dụng thấp nhất",
        MinWeight: "Trọng lượng sử dụng tối thiểu"
    },
    declare:{
        rules: {
            courier: {
                required: 'Vui lòng nhập số vận đơn',
            },
            storehouse: {
                required: 'Vui lòng chọn kho hàng nước ngoài',
            },
            company: {
                required: 'Vui lòng chọn công ty vận chuyển',
            },
            type: {
                required: 'Vui lòng chọn loại hàng',
            },
            classification: {
                required: 'Vui lòng chọn phân loại hàng',
            },
            goodsName: {
                required: 'Vui lòng nhập tên hàng',
            },
            goodsNum: {
                required: 'Vui lòng nhập số lượng hàng',
            },
            currency: {
                required: 'Vui lòng chọn loại tiền tệ',
            },
            value: {
                required: 'Vui lòng nhập giá trị hàng',
            },
        },
        title:"Khai báo hàng hóa từ nước ngoài đến Hồng Kông",
        code:"Số vận đơn",
        codeplaceholder:"Vui lòng nhập số vận đơn",
        findcode:"Làm thế nào để tìm số vận đơn?",
        storehouse:"Kho hàng nước ngoài",
        company:"Công ty vận chuyển",
        type:"Loại hàng",
        classification:"Phân loại hàng",
        goodsname:"Tên hàng",
        goodsNum:"Số lượng hàng",
        currency:"Loại tiền tệ",
        value:"Giá trị hàng",
        reset:"Làm mới",
        submit:"Gửi",
        placeholderMark: "Ghi chú: Mô tả văn bản",
        notice1: 'Khi khai báo hàng hóa, hãy chọn đúng kho hàng nước ngoài. Nếu số vận đơn đúng nhưng kho không đúng, trạng thái sẽ luôn hiển thị là chưa ký nhận.',
        notice2: 'Vui lòng nhập tên hàng bằng tiếng Anh.',
    },
    common: {
        title: "Bình luận",
        submit: "Gửi",
        imageValidate: "Kích thước hình ảnh không được vượt quá 5MB!"
    },
    notify:{
        title:"Thông báo qua ứng dụng",
        emailmsg:"Thông báo qua Email",
        unverified:"Phát hiện rằng địa chỉ email của bạn chưa được xác minh",
        latestnotice:"Thông báo ưu đãi mới nhất",
        intelligentservice:"Dịch vụ thông minh",
        rem:"Hàng hóa khi đến kho sẽ được tự động sắp xếp vào tài khoản của bạn"
    },
    RightBox: {
        title: "Quay lại đầu trang"
    },
    order:{
        deleteOrder: {
            confirm: 'Thao tác này sẽ xóa vĩnh viễn đơn hàng có số ({orderId}), bạn có muốn tiếp tục không?',
            prompt: 'Lưu ý',
            confirmText: 'Xác nhận',
            cancelText: 'Hủy',
            success: 'Xóa thành công',
            cancelDelete: 'Đã hủy xóa',
        },
        deliveryOrder: {
            confirm: "Thao tác này sẽ xác nhận đơn đặt hàng với số nhận ({0}), bạn có tiếp tục không?",
            Prompt:'Gợi ý',
            confirmText:'OK',
            cancelText:'Hủy bỏ',
            Success: "Nhận hàng thành công",
            cancel:'Thu hoạch bị hủy bỏ',
        },
        shipmentCode: "Số đơn hàng",
        delivery: "Xác nhận nhận hàng",
        trajectory: 'Lịch trình vận chuyển',
        confirm: 'Xác nhận',
        emptyTrajectory: "Không có lịch trình vận chuyển",
        CreateTime: "Thời gian đặt hàng",
        placeholder: "Vui lòng nhập số đơn hàng",
        Yes: 'Có',
        No: "Không",
        rate: 'Đánh giá',
        Weight: "Trọng lượng(KG)",
        title:"Thông tin đơn hàng",
        mainbillcode:"Số đơn hàng",
        id:"ID",
        export: "Xuất khẩu",
        exportOrder: "Xuất đơn đặt hàng",
        separator: "đến",
        startTime: "Ngày bắt đầu",
        endTime: "Ngày kết thúc",
        orderid:"Số đơn hàng",
        carrierbillcode:"Số vận đơn",
        goodsname:"Tên hàng",
        receiveinfo:"Thông tin người nhận",
        receivename:"Tên người nhận",
        receivephone:"Số điện thoại liên hệ",
        receivetelephone:"Số điện thoại liên hệ",
        receiveaddress:"Địa chỉ nhận hàng",
        receiveidcard:"Số CMND",
        receivepostcode:"Mã bưu chính",
        recipientaddress:"Địa chỉ nhận hàng",
        destination:"Điểm đến",
        selectdestination:"Vui lòng chọn điểm đến",
        province:"Tỉnh/Thành phố",
        selectprovince:"Vui lòng chọn tỉnh/thành phố",
        city:"Thành phố",
        selectcity:"Vui lòng chọn thành phố",
        area:"Quận/Huyện",
        selectarea:"Vui lòng chọn quận/huyện",
        warehouse:"Kho hàng",
        goodstype:"Loại hàng",
        timer:"Thời gian dự kiến",
        carrier:"Nhà vận chuyển",
        orderrem:"Ghi chú khi đặt hàng",
        deliverytitle:"Thông tin giao hàng",
        ordercode:"Số vận đơn",
        logistics:"Công ty vận chuyển",
        packagetitle:"Thông tin gói hàng",
        rem:"Ghi chú",
        account:"Số lượng",
        daysinstorage:"Số ngày tại kho",
        size:"Kích thước",
        weight:"Trọng lượng thực",
        surcharge:"Phụ phí",
        count:"Tổng số lượng",
        grossweight:"Tổng trọng lượng",
        grosssurcharge:"Tổng phụ phí",
        storagecharges:"Phí kho",
        weightcharges:"Phí vượt cân",
        lengthcharges:"Phí vượt kích thước",
        fareweight:"Tổng trọng lượng tính phí",
        receivinginfo:"Thông tin người nhận",
        declarationinfo:"Thông tin khai báo",
        declarename:"Tên người khai báo",
        declarePhone:"Số điện thoại liên hệ",
        declaretelephone:"Số điện thoại liên hệ",
        declareidcard:"Số CMND",
        declarepostcode:"Mã bưu chính",
        declareaddress:"Địa chỉ khai báo",
        declareaccount:"Số lượng đã khai báo",
        declareprice:"Giá trị đã khai báo",
        feeinfo:"Thông tin phí",
        collectionamount:"Số tiền thu hộ",
        taxfee:"Phí thuế",
        insuredamount:"Số tiền bảo hiểm",
        couponamount:"Số tiền giảm giá",
        integralamount:"Số tiền giảm giá bằng điểm tích lũy",
        destinationcharges:"Phí điểm đến",
        istax:"Có đóng thuế",
        istopay:"Có thanh toán khi nhận hàng",
        totalfee:"Tổng phí",
        freight:"Phí vận chuyển",
        collectioncommission:"Phí thu hộ",
        insuredcommission:"Phí bảo hiểm",
        addresssurcharge:"Phí địa chỉ",
        addedservicefee:"Phí dịch vụ bổ sung",
        discount:"Giảm giá",
        off:"%",
        accruedcharge:"Tiền tệ quốc gia đích({0})",
        paytype:"Phương thức thanh toán",
        payment:"Thanh toán",
        paystate:"Trạng thái thanh toán",
        cashier: "Thu ngân",
        paid:"Đã thanh toán",
        nonpayment:"Chưa thanh toán",
        createtime:"Thời gian đặt hàng",
        printer:"In",
        domestic:"Tất cả các đơn hàng",
        operate:"Thao tác",
        details:"Chi tiết",
        delete:"Xóa",
        selection:"Đã chọn",
        deleteorder:"Xóa đơn hàng",
        disposition:"Quản lý cấu hình",
        role:"Quản lý vai trò",
        orderdetails:"Chi tiết đơn hàng",
        underway:"Chờ giao hàng",
        shipped: "Đã giao hàng",
        completed:"Đã hoàn thành",
        unPaid: "Chưa thanh toán",
        orderSourceName: "Nguồn order"
    },
    recharge: {
        title: "Nạp tiền vào tài khoản",
        balance: "Số dư",
        money: "Số tiền nạp",
        confirmMoney: "Số tiền",
        submit: "Xác nhận nạp tiền",
        warning: "Vui lòng chọn số tiền nạp!",
        inventory: {
            title: "Chi tiết đơn hàng: Nạp tiền vào tài khoản",
            balance: "Số dư tài khoản",
            pon: "Số đơn hàng ảo"
        }
    },
    pickupPoint: {
        Name: "Tên điểm tự lấy",
        TypeText: "Loại hình",
        DestinationName: "Điểm đến",
        ProvinceName: "Tỉnh/Thành phố",
        CityName: "Thành phố",
        AreaName: "Quận/Huyện",
        Address: "Địa chỉ chi tiết",
        Phone: "Số điện thoại liên hệ",
        Tel: "Số điện thoại",
        BusinessHours: "Giờ làm việc",
        Postcode: "Mã bưu chính",
        ServiceCharge: "Phí dịch vụ",
        StorageLife: "Thời gian lưu trữ",
        Longitude: "Kinh độ",
        Latitude: "Vĩ độ",
        Rem: "Ghi chú",
        AgentStateText: "Trạng thái mở cửa",
        Add: "Thêm vào địa chỉ nhận hàng"
    },
    line: {
        title: "Đặt hàng Đường dây chuyên dụng",
        loadingText: "Đang gửi biểu mẫu...",
        ClientCode: "Số đơn hàng của khách hàng",
        GoodsName: "Tên hàng hóa",
        DestinationId: "Điểm đến",
        placeholder: "Vui lòng chọn",
        GoodsTypeId: "Loại hàng hóa",
        CarrierId: "Nhà vận chuyển",
        TimerId: "Thời gian",
        WareHouseId: "Kho tiếp nhận",
        RecipientName: "Tên người nhận",
        RecipientPhone: "Điện thoại người nhận",
        RecipientEmail: "Email người nhận",
        RecipientPostCode: "Mã bưu điện người nhận",
        RecipientAddress: "Địa chỉ người nhận",
        Rem: "Ghi chú",
        submitForm: "Đặt hàng ngay",
        resetForm: "Đặt lại",
        placeholderDestinationId: "Vui lòng chọn điểm đến",
        placeholderGoodsTypeId: "Vui lòng chọn loại hàng hóa",
        placeholderCarrierId: "Vui lòng chọn nhà vận chuyển",
        placeholderTimerId: "Vui lòng chọn thời gian",
        placeholderWareHouseId: "Vui lòng chọn kho",
        placeholderClientCode: "Vui lòng nhập số đơn hàng của khách hàng",
        placeholderGoodsName: "Vui lòng nhập tên hàng hóa",
        placeholderRecipientAddress: "Vui lòng nhập địa chỉ người nhận",
        placeholderRecipientPhone: "Vui lòng nhập điện thoại người nhận",
        placeholderRecipientName: "Vui lòng nhập tên người nhận",
        submitSuccess: "Đặt hàng thành công!!!"
    },
    personal:{
        withdrawalMoney: {
            Amount: "Số tài khoản",
            BankName: "Mở tài khoản ngân hàng",
            OpenName: "Tên chủ tài khoản",
            BankCard: "Số thẻ ngân hàng",
            submit: "Yêu cầu rút tiền",
            cancel: "Hủy",
            title: "Rút tiền từ số dư (Số dư tài khoản: {0})",
            placeholderAmount: "Vui lòng nhập số tiền cần rút",
            placeholderOpenName: "Vui lòng nhập tên chủ tài khoản",
            placeholderBankName: "Vui lòng nhập Mở tài khoản ngân hàng",
            placeholderBankCard: "Vui lòng nhập số thẻ ngân hàng",
            withdrawalSuccess: "Yêu cầu rút tiền đã được gửi!"
        },
        Rem: "Ghi chú",
        sign: "Đăng ký",
        signCalendar: "Đăng nhập vào lịch",
        signSuccess: "Đăng ký điểm thưởng thành công ({0})",
        withdrawalMoneyTitle: "Rút tiền",
        package: "Gói hàng của tôi",
        Order: "Lệnh của tôi",
        notice: "Thông báo",
        confirm: "Xác nhận",
        balance: "Nạp tiền",
        domestic:"Kho hàng nội địa",
        address: "Địa chỉ nhận hàng của tôi",
        recharge: "Nạp tiền",
        arrived:"Đã đến kho",
        recommend: "Giới thiệu bạn bè",
        commission: "Tiền hoa hồng có thể rút",
        withdrawal: "Tiền hoa hồng có thể rút",
        unarrive:"Chưa đến kho",
        notplaceorder:"Đặt hàng",
        placedorder:"Đang chờ yêu cầu",
        domesticaddress:"Địa chỉ kho hàng nội địa",
        cargoclaim:"Yêu cầu nhận hàng",
        combinedcargo:"Gộp hàng",
        myorder:"Đơn hàng của tôi",
        username:"Tên thành viên",
        amount: "Số dư tài khoản",
        membernumber:"Mã thành viên",
        accountnumber:"Số tài khoản",
        deliveryaddress:"Địa chỉ nhận hàng",
        mycoupon:"Mã giảm giá của tôi",
        recommendedfriend:"Giới thiệu bạn bè",
        integral:"Điểm tích luỹ",
        coupon:"Mã giảm giá có sẵn",
        membercenter:"Trung tâm thành viên",
        ContactPerson: "Người liên hệ",
        Phone: "Số điện thoại",
        PostCode: "Mã bưu chính",
        DetailedAddress: "Địa chỉ chi tiết",
        copyAddress: "Sao chép địa chỉ",
        title: "Địa chỉ kho hàng nội địa",
        pendingPayment: "Chờ TT",
        toBeShipped: "Chờ giao",
        shipped: "Đã giao",
        completed: "Đã xong",
    },
    procedure:{
        demonstrate: {
            step1: {
                title: 'Đăng ký tài khoản và trở thành thành viên',
                subTitle: ''
            },
            step2: {
                title: 'Nhận và chọn địa chỉ kho hàng tổng để làm địa chỉ nhận hàng trên các nền tảng mua sắm',
                subTitle: ''
            },
            step3: {
                title: 'Yêu cầu nhận hàng đã mua về trang web của chúng tôi',
                subTitle: ''
            },
            step4: {
                title: 'Sau khi hàng đến kho hàng, yêu cầu giao hàng',
                subTitle: ''
            },
            step5: {
                title: 'Khi hàng đến địa chỉ đích, tự lấy hoặc giao hàng tận nơi',
                subTitle: ''
            }
        },
        servers: {
            step1: {
                title: 'Tự lấy hàng',
                introduces: [
                    'Với hơn 300+ điểm tự lấy hợp tác, hãy chọn cửa hàng phù hợp nhất cho bạn!\n' +
                    'Ưu điểm dịch vụ: Tiết kiệm chi phí, sắp xếp thời gian linh hoạt, kiểm soát quy trình giao hàng',
                    '·  Gửi hàng đến điểm logistics đã chỉ định.',
                    '· Sau khi logistics giao hàng, thông báo cho người nhận hàng.',
                    '· Người nhận hàng mang theo giấy tờ tùy thân hợp lệ cùng mã lấy hàng hoặc mã vận đơn liên quan, đến địa điểm chỉ định để thực hiện thủ tục lấy hàng.'
                ]
            },
            step2: {
                title: 'Giao hàng tận nhà',
                introduces: [
                    'Nhân viên giao hàng sẽ giao hàng trong khoảng thời gian đã hẹn',
                    'Và hoàn thành thủ tục ký nhận với người nhận hàng'
                ]
            },
            step3: {
                title: 'Theo dõi thông tin vận chuyển hàng hóa theo thời gian thực',
                introduces: [
                    'Khi trạng thái đơn hàng thay đổi, hệ thống sẽ cập nhật và đồng bộ hóa với nền tảng tra cứu của chúng tôi',
                    'Trên trung tâm thành viên hoặc ứng dụng di động, nhập mã vận chuyển duy nhất của bạn để xem chi tiết của quá trình vận chuyển hàng hóa',
                    '24/7 sẵn có, bất kể thời gian và nơi nào, bạn có thể tra cứu vị trí và trạng thái của hàng hóa bất cứ lúc nào'
                ]
            },
            // Các bước khác...
        },
        process:"Quy trình gom hàng",
        learnmore:"Tìm hiểu thêm",
        checkMore: "Xem địa chỉ kho hàng",
        warehouseIntroduction: 'Giới thiệu kho hàng gom hàng',
        warehouseLocation: 'Có diện tích tổng cộng hơn 10000 mét vuông với kho hàng hiện đại, phân khu rõ ràng, phù hợp với các nhu cầu lưu trữ hàng hóa khác nhau, vị trí địa lý tọa lạc tại các khu vực trung tâm giao thông, gần các cảng biển, sân bay hoặc các mạng lưới đường cao tốc chính, đảm bảo hàng hóa có thể được phân phối và vận chuyển nhanh chóng, thuận tiện, giảm chi phí vận chuyển',
        warehouseFunction: 'Sử dụng công nghệ kho hàng tự động hóa tiên tiến, thực hiện quản lý kho hàng nhập, xuất và tồn kho hiệu quả và chính xác, giám sát video 24/7, trang thiết bị phòng cháy đầy đủ, đồng thời có đội ngũ bảo vệ chuyên nghiệp, đảm bảo an toàn hàng hóa',
        learnMore: 'Tìm hiểu thêm',
        weightCalculation: 'Tính trọng lượng',
        weightCalculationNote1: 'Trong việc tính phí vận chuyển, trọng lượng tính phí dựa trên hai yếu tố: trọng lượng thực tế và trọng lượng khối. Trọng lượng thực tế là trọng lượng của hàng hóa; trọng lượng khối là cách tính trọng lượng tương đương dựa trên kích thước của bưu kiện.',
        weightCalculationNote2: 'Trọng lượng thực tế: Trọng lượng của hàng hóa được cân trực tiếp.',
        weightCalculationNote3: 'Trọng lượng khối: Tính theo công thức (dài x rộng x cao ÷ hệ số tính phí), và có các tiêu chuẩn hệ số tính phí khác nhau tùy thuộc vào phương thức vận chuyển.',
        weightCalculationNote4: 'Phí vận chuyển sẽ dựa trên trọng lượng thực tế và trọng lượng khối, lấy giá trị lớn nhất trong hai giá trị làm cơ sở tính phí. Ngoài ra, đối với một số hàng hóa hoặc dịch vụ đặc biệt, cũng có thể có các quy định bổ sung như trọng lượng tối thiểu tính phí, phụ phí siêu trọng lượng, v.v. Nếu có bất kỳ thắc mắc hoặc cần báo giá chi tiết, vui lòng liên hệ với đội ngũ dịch vụ khách hàng của chúng tôi.',
        declarationTitle: 'Khai báo hàng hóa',
        declarationNote1: 'Đảm bảo tất cả thông tin hàng hóa như tên hàng, kích thước, số lượng, giá trị, v.v., đều giống như hàng hóa thực tế và cung cấp hóa đơn mua hàng, danh sách đóng gói và các chứng chỉ liên quan đầy đủ và chính xác, để quá trình khai báo hàng hóa trong quá trình nhập, xuất hàng diễn ra suôn sẻ, giảm thiểu rủi ro hải quan',
        shippingPackage: 'Dịch vụ gia tăng',
        packageNote1: 'Nếu hàng hóa của bạn cần được mở bao bì do quá nhiều bọt, hoặc bao bì bên ngoài có hình dạng đa giác và không thể đóng gói vào hộp gói hàng quốc tế, chúng tôi cung cấp dịch vụ đóng gói lại miễn phí. Sau khi đóng gói lại, thể tích sẽ giảm tương ứng, nhưng không tuyệt đối.',
    }

}