export default {
  headers: {
    index: 'Home',
    message: 'Announcement Message',
    query: 'Logistics Query',
    login: 'Login',
    register: 'Register',
    logout: 'Logout',
    member: 'Container Center'
  },
  utils: {
    title: "Logout Confirmation",
    confirm: "Log In Again",
    cancel: "Cancel",
    message: "Your Login Has Expired. You Can Cancel To Return To The Homepage Or Log In Again."
  },
  advantageBox: {
    team: {
      title: 'Professional Team',
      subtitle1: 'Experienced Professional Team',
      subtitle2: 'Provide Full After-sales Service'
    },
    qualityControl: {
      title: 'Quality Control And Safety Assurance',
      subtitle1: 'Innovation Control And Safety Assurance, Strict Quality Control In Every Link',
      subtitle2: 'Ensure The Safety And Integrity Of The Goods'
    },
    innovativeTechnology: {
      title: 'Innovative Technology Applications',
      subtitle1: 'Use Advanced Consolidation System To Improve Operational Efficiency And Track Cargo Status In Real Time',
      subtitle2: 'Make Sure You Reach Your Destination Safely And Quickly'
    },
    diverseService: {
      title: 'Service Diversification',
      subtitle1: 'Flexible Shipping Options',
      subtitle2: 'Customizable Packaging Scheme'
    }
  },
  immediate: {
    title: "Immediate Dispatch",
    placeholderTimer: "Select delivery time",
    timer: "Delivery Time",
    channel: "Channel",
    placeholderChannel: "Select channel",
    rem: "Notes",
    placeholderRem: "Please leave any additional requirements or special notes here",
    submit: "Submit",
    submitSuccess: "Submission Successful!",
    addWarning: "The number of items added has reached the limit!"
  },
  exhibitBox: {
    upper: {
      members: '10000 + members',
      dailyGoodsWarehousing: 'Daily Warehousing 30000 Of Goods +',
      dailyGoodsStorage: 'Daily Warehousing Of Goods 30000 +',
      dailyOrders: 'Daily Orders 10000 +',
      dailyShipments: 'Daily Shipments 10000 +'
    },
    lower: {
      dailyShipments: 'Daily Shipments 10000 +',
      cooperativePickupPoints: 'Cooperative Pickup Points 600 +'
    }
  },
  toolBox: {
    teach: {
      title: 'Collection And Transportation Teaching',
      subtitle: 'Graphic And Video Tutorial Guide'
    },
    volume: {
      title: 'Volume Trial',
      subtitle: 'Trial Calculation Of Cargo Volume And Weight'
    },
    shipping: {
      title: 'Freight Trial Calculation',
      subtitle: 'Order Freight Calculation'
    },
    chargeIntroduction: {
      title: 'Charge Introduction',
      subtitle: 'Charging Introduction And Related Description'
    }
  },
  introduce: {
    destination: 'Destination',
    CalculationModeName: 'Calculation Mode',
    CarryModeName: 'Carry Mode',
    CarrierNames: 'Carrier',
    GoodsTypeNames: 'Cargo Type',
    TimerNames: 'Time-effective',
    range: 'Range',
    firstWeight: 'First Weight',
    unitPrice: 'Unit Price',
    surchargeFormula: 'Surcharge (Z For Weight)',
    unit: 'unit',
    FirstWeight: "First Weight",
    FirstPrice: "First Price",
    FirstSurcharge: "First Surcharge"
  },
  paymentCard: {
    paymentMethod: 'Payment Method',
    wechatPay: 'Wechat Pay',
    alipayPay: 'Alipay Pay',
    balancePay: 'Balance Payment',
    paymentAmount: 'Payment Amount',
    rechargeAmount: "Recharge amount",
    paidInAmount: "Paid-in amount"
  },
  QrCodePay: {
    paymentAmount: 'Payment Amount',
    orderNumber: 'Order Number',
    orderDescription: 'Order Description',
    bankDeposit: "Bank Deposit",
    openingName: "Account Holder's Name",
    account: "Account",
    prompt: "Upload Payment Receipt",
    transactionNo: "Transaction Number",
    onSubmit: "Submit Application",
    placeholderTransactionNo: "Please fill in the Transaction Number",
    placeholderOffline: "Please upload the transaction receipt",
    successMsg: "successfully submitted. Please wait for the administrator's review!",
    rem: "Remark"
  },
  agent: {
    title: "Become An Agent With Continuous Profits",
    referralCode: "My Referral Code",
    download: "Download Agent QR Code",
    copyLink: "Replication Agent Link",
    WithdrawalMoney: "Current Proxy Earnings",
    promotionNumber: "Number Of Proxy Members",
    withdrawal: "Withdrawal Proceeds To Balance",
    member: "Member Details",
    id: "Member Number",
    name: "Member Name",
    createTime: "Registration Time",
    lastLoginTime: "Last Active Time",
    withdrawalIncome: "Withdrawable Income",
    allWithdrawal: "Full withdrawal",
    label: "Input Withdrawal Amount：",
    submit: "Confirm Cash Withdrawal",
    placeholder: "Please Enter The Withdrawal Amount",
    success: "Cash Withdrawal Successful！",
    recommend: {
      title: "Recommend us To Your Friends",
      referralCode: "My Referral Code",
      download: "Download Agent QR Code",
      copyLink: "Replication Agent Link",
      promotionNumber: "Number Of Promotion Members",
      member: "Member Details",
      id: "Member Number",
      name: "Member Name",
      createTime: "Registration Time",
      lastLoginTime: "Last Active Time",
    }
  },
  flow: {
    title: "Total Consumption",
    residue: "Remaining Available",
    thisWeek: "Spend This Week",
    today: "Amount Spent Today",
    placeholderTransactionNo: "Please Enter The Serial Number",
    placeholderWalletLogType: "Consumption Type",
    search: "Query",
    TypeName: "Transaction Type",
    CreateTime: "Transaction Time",
    VariableState: "State",
    Money: "Variable Money",
    OldMoney: "Before Change",
    OrderNo: "System Order No",
    TransactionNo: "Transaction Serial Number",
    Rem: "Comment Description",
    Balance: "Balance",
    Integral: "Integral"
  },
  comment: {
    title: "Please Write Down Your Overall Satisfaction With The Delivery Service",
    prompt: "Write Your Review",
    placeholder: "Please Enter Content",
    veryPoor: "Extremely Poor",
    disappointed: "Disappointed",
    average: "Average",
    satisfied: "Satisfied",
    verySatisfied: "Very Satisfied",
    callback: "Thank You For Your Comments",
    mainTitle: "Why US？",
    subTitle: "See What The Other Partners Say",
    reply: "Merchant Reply"
  },
  cashier: {
    paymentMethod: 'Payment Method',
    payNow: 'Immediate Payment',
    cancel: 'Cancel',
    confirmBalance: 'Will you use The balance to pay ￥({Receivables})？"',
    prompt: "Tips",
    confirm: "Verify",
    cancelDelete: "Cancelled Payment",
    successPayment: "Payment Success",
    warning: "Please Select A Payment Method"
  },
  footer: {
    domesticConsolidation: 'Related To The Consolidation Process',
    domesticDeliveryService: 'Hong Kong Delivery Service',
    domesticStorePickup: 'Hong Kong Stores For Themselves',
    more: 'More...',
    overseasConsolidation: 'Overseas Consolidation',
    overseasDeliveryService: 'Overseas Delivery Service',
    overseasStorePickup: 'Overseas Stores Take Their Own Money',
    noviceTeach: 'Novice Teaching',
    frequentlyQuestion: 'Frequently Asked Questions',
    freightCalculation: 'Freight Calculation',
    earnPoints: 'Help Center',
    cashRoll: 'How To Get Coupons',
    payment: 'How To Pay',
    companyProfile: 'About US',
    declarationProtection: 'Privacy Statement',
    privacyStatement: 'Application Privacy Notes',
    disclaimers: 'Terms Of Use And Disclaimer',
    acceptCarriage: 'Treaty Of Carriage',
    contactUs: 'Contact US',
    filings: '@ 2023 Zhanbei All Rights Reserved'
  },
  download: {
    ios: 'Install IOS',
    android: 'Install Android'
  },
  mergerCard: {
    popover: {
      CarryWeight: "Billing Weight",
      FirstWeight: "First Weight",
      FirstPrice: "First Weight Price",
      FirstWeightSurcharge: "First Weight Surcharge",
      LastWeight: "Continue",
      LastWeightUnitPrice: "Renewal Unit Price",
      LastWeightSurcharge: "Renewal Surcharge"
    },
    Channel: "channel",
    placeholderChannel: "Please select a channel",
    VolumeWeight: "Volume Weight",
    totalVolumeWeight: "Total volume weight",
    validateIntegral: "Points exceed the limit owned",
    yes: "Yes",
    no: "No",
    preferential: "Discounts Available",
    servicePrice: "Service charge",
    Discount: "Discount rate",
    InsuredCommission: "Insurance Commission",
    AgenServiceFree: "Pick UP The Service Charge",
    CouponAmount: "Coupon Amount",
    IntegralAmount: "Point Deduction",
    AddedServicesFee: "Value-added Service Cost",
    TaxCommission: "Tax Commission",
    CollectionCommission: "Collection fee",
    DestinationCharges: "Destination Charge({CalculateFee.CurrencyName})：{CalculateFee.DestinationCharges}",
    AccruedCharge: "Expense Payable",
    DeclareAccount: "Declared Quantity",
    Size: "Size",
    Weight: "Weight",
    step1: 'Package Information',
    step2: 'Order Delivery',
    step3: 'Order Information',
    step4: 'Order Payment',
    ServiceType: 'Service Mode',
    placeholderServiceType: 'Please Select A Service Method',
    Code: 'Courier No',
    Amount: 'Amount',
    GoodName: 'Product Name',
    GoodsTypeName: 'Cargo Type',
    Surcharge: 'Surcharge（RMB）',
    totalCount: 'Total Quantity',
    totalWeight: 'Total Weight',
    totalSurcharge: 'Surcharge',
    placeholderGoodsTypeName: 'Please Select A Goods Type',
    Carrier: 'Carrier',
    placeholderCarrier: 'Please Select A Carrier',
    Timer: 'time-effective',
    placeholderTimer: 'Please select time-effective',
    AddedService: 'Added Service',
    placeholderAddedService: 'Please Select A Value-added Service',
    Integral: 'Integral',
    available: 'Available',
    Coupon: 'Coupon',
    placeholderCoupon: 'Please Select A Coupon',
    ClientAddress: 'Deliver TO',
    placeholderClientAddress: 'Please Select An Address',
    CollectionAmount: 'Collect Payment For Goods',
    InsuredAmount: 'Insured Amount',
    IsTax: 'Tax Included Or Not',
    placeholderIsTax: 'Please Select Whether To Include Tax Or Not',
    DeclareName: 'Declarer',
    DeclarePhone: 'Declare Phone',
    DeclareAddress: 'Declare Address',
    DeclareIdCard: 'Declare Id Card',
    DeclarePostCode: 'Declare Post Code',
    DeclareEmail: 'Declare Email',
    DeclareTelephone: 'Declare Telephone',
    DeclareMoney: 'Total Amount Declared',
    DeclarePrice: 'Declare Unit Price',
    GoodsName: 'Name Of Goods',
    GoodsName_En: 'English Name Of Goods ',
    feeDetail: 'Expense Detail',
    weight: 'Weight',
    Freight: 'Freight',
    FreightDetail: 'Detail Of Freight Calculation',
    CalculateFeeSurcharge: 'Parcel Surcharge',
    CalculateFeeAddressSurcharge: 'Address Surcharge',
    CalculateFeeStorageCharges: 'Storage Charges',
    CalculateFeeLengthExceed: 'Overlength Charges',
    packageCount: 'Parcel Quantity',
    CalculateFeeWeightExceed: 'Overweight Charge',
    paymentMethod: 'Payment Method',
    back: 'Last Step',
    next: 'Next Step',
    settlement: 'Settle An Account',
    placeholderGoodsName_En: 'Please Enter The Name In English',
    placeholderGoodsName: 'Please Enter Name Of Goods',
    validatePostalCode: 'Please Enter A Valid China Postal Code',
    validateLandline: 'Please Enter A Valid Landline Number In Mainland China',
    validateEmail: 'Please Enter A Valid Email Address',
    validateMobile: 'Please Enter A Valid China Mobile Phone Number',
    validateCreditCode: 'Please Enter The Official Registration Or ID Card',
    paySuccess: 'Payment Success！！！',
    payInfo: 'Cancelled Payment！！！，Please Pay In The Order List',
    orderSuccess: 'Order Successful！Calling Payment...'
  },
  route: {
    index: 'Home Page',
    member: 'Member Center',
    personal: 'Personal Center Overview',
    account: 'Personal Information Setting',
    flow: 'Consumption Record',
    coupon: 'My Coupon',
    notify: 'Notification Settings',
    ConsolidatedDomestic: 'Combined Cargo',
    OrderDomestic: 'My Order',
    AddressDomestic: 'Delivery Address',
    declare: 'Cargo Declaration',
    claimDomestic: 'Application Forecast',
    chargeIntroduce: 'Fee Introduction',
    service: 'Fees And Services',
    agent: "Agent Center",
    recommend: "Recommend A Friend",
    help: 'Help',
    Consolidation: 'Consolidation Shipping Instructions',
    problem: 'FAQ',
    contraband: 'Transporting Contraband',
    VolumeConversion: 'Volume Calculation',
    ShippingCalculation: 'Freight Calculation',
    teach: 'Novice Teaching',
    lostHandle: 'Lost Parts Disposal',
    precautions: 'Matters Needing Attention',
    message: 'Latest News',
    logistics: 'Logistics Inquiry',
    pickupPoint: 'Self-pickup Point Supply',
    news: 'Announcement Details',
    procedureDomestic: 'Domestic Consolidation Shipping',
    Login: 'Login',
    Register: 'Register',
    added: 'Value Added Service Types',
    addedList: 'Value Added Service List',
    shopeeShop: 'Store Management',
    shopeeOrder: 'order management',
    immediate: "Instant delivery upon arrival",
    upload: "Peer import"
  },
  upload: {
    save: "Save",
    selectFile: "Select Import File",
    downloadTemplate: "Download Template File",
    delete: "Delete",
    loadingText: "Loading...",
    errorMsg: "Data Error Message",
    mainBillCode: "Order Number",
    carrierBillCode: "Carrier Bill Number",
    subbillNumber: "Sub-bill Number",
    parcelCode: "Parcel Number",
    skuCode: "SKU",
    clientRem: "Client Note",
    rem: "Order Note",
    goodsTypeId: "Goods Type",
    timerId: "Delivery Time",
    carrierId: "Carrier",
    goodsCount: "Goods Quantity",
    goodsName: "Goods Name",
    goodsNameEn: "English Name",
    destinationId: "Destination",
    province: "Province",
    city: "City",
    area: "Area",
    collectionAmount: "Collect on Delivery Amount",
    insuredAmount: "Quoted Amount",
    weight: "Actual Weight",
    isTax: "Is Tax Included",
    agentPointId: "Pick-up Point",
    idCard: "ID Card Number",
    postNo: "Postal Code",
    receiveName: "Recipient Name",
    receiveAddress: "Recipient Address",
    receivePhoneNo: "Recipient Mobile",
    receiveTel: "Recipient Phone",
    email: "Email",
    declareName: "Declarant Name",
    declareAddress: "Declarant Address",
    declareTel: "Declarant Phone",
    declarePhoneNo: "Declarant Mobile",
    declareIdCard: "Declarant ID",
    declarePrice: "Declared Unit Price",
    declareCount: "Declared Quantity",
    declareValue: "Declared Value",
    length: "Length",
    width: "Width",
    height: "Height",
    createTime: "Order Time",
    dialogTitle: "Prompt",
    warehouseLabel: "Warehouse",
    submitButton: "Submit",
    placeholderWarehouse: "Please select warehouse",
    templateName: "Template{0}.xlsx",
    downloadError: "Download Failed: {0}",
    downloadSuccess: "Download Successful",
    exportSuccess: "Import Successful!",
    validateError: "There is abnormal data. Please check and submit again.",
    prompt: "Prompt",
    message: "This operation will delete the data for order number ({0}). Do you want to continue?",
    confirm: "Confirm",
    cancel: "Cancel",
    deleteSuccess: "Delete Successful!"
  },
  login: {
    title: 'Zhanbei Logistics',
    subTitle1: 'Making Operations Simpler',
    subTitle2: 'Goods Are Anxious To Find The Zhanbei',
    subTitle3: '3000+ Corporate Partners',
    subTitle4: "To Satisfy You, I'm Refreshing My Speed.",
    login: 'Member Login',
    account: 'Username',
    password: 'Password',
    register: 'Sign Up',
    forgetPassword: 'Forgot Password',
    submit: 'Log In',
    placeholderAccount: 'Please Enter Your Username.',
    placeholderPassword: 'Please Enter Your Password.'
  },
  register: {
    title: 'Zhanbei Logistics',
    subTitle1: 'Making Operations Simpler',
    subTitle2: 'Goods Are Anxious To Find The Zhanbei',
    subTitle3: '3000+ Corporate Partners',
    subTitle4: "To Satisfy You, I'm Refreshing My Speed",
    item: 'Become A {0} Member',
    account: 'Username',
    password: 'Password',
    phone: 'Phone Number',
    aws: 'Taobao Want Want Number, Amazon Shop Account',
    notice: 'Agree To Receive All Emails From This Site',
    term: 'Accept {0}',
    termTitle1: '[Services And Treaties]',
    termTitle2: '[Carrier Contract Terms]',
    termTitle3: '[Precautions]',
    termTitle4: '[Lost Item Handling]',
    termUse: 'Of Terms',
    hasAccount: 'Existing Account Login',
    submit: 'Registration',
    placeholderAccount: 'Please Enter Username',
    placeholderPassword: 'Please Enter Password',
    placeholderPhone: 'Please Enter Phone Number',
    mainlandPhone: 'Please Enter 11-Digit Phone Number',
    twPhone: 'Please Enter 8-Digit Phone Number',
    placeholderTerm: 'Please Read Terms And Accept！',
    success: 'Registration Successful！',
    Agent: "Agent User ID",
    Recommender: "Recommended friend ID",
    Email: "Email",
    Prompt: "Services and Contracts"
  },
  message: {
    couponsState: {
      upToDate: 'Latest Announcement',
      preferential: 'Promotional Activities',
    },
    releaseTime: "Release Time",
    date: "Time",
    content: "Title Content",
    activeTime: 'Event Time'
  },
  contraband:{
    title:"Transportation And Storage Of Prohibited Goods",
  },
  losthandle:{
    title:"Lost Item Handling",
  },
  problem:{
    domestic:"Domestic Consolidation Shipping",
    foreign:"Overseas Consolidation Shipping",
  },
  shippingcalculation:{
    title:"Freight Calculation",
    destination:"Destination",
    goodtype:"Goods Type",
    volume:"Volume（Unit）",
    length:"Please Enter Goods Length",
    width:"Please Enter Goods Width",
    height:"Please Enter Goods Height",
    weight:"Please Enter Goods Weight",
    submit:"Calculation",
    result:"Freight Calculation Result",
    warehouseName:"Warehouse",
    dialogItems: {
      goodsType: 'Goods Type',
      timer: 'time-effective',
      carrier: 'Carrier',
      fee: 'Price(RMB)',
      lengthExceed: 'Extra Length Charge(RMB)',
      carryWeight: 'Calculated Weight(KG)'
    },
    rules: {
      selectWarehouse: 'Please Choose Warehouse',
      selectDestination: 'Please Choose Destination',
      selectGoodType: 'Please Choose Goods Type',
      enterLength: 'Please Enter Goods Length',
      enterWidth: 'Please Enter Goods Width',
      enterHeight: 'Please Enter Goods Height',
      enterWeight: 'Please Enter Goods Weight'
    },
  },
  teach:{
    title:"Registration/Registered Account",
    title1: "Video Tutorial",
    title2: "Graphic Text Tutorial",
    loading: "Loading"
  },
  volume:{
    title:"Volume Calculation",
    length:"Length",
    width:"Width",
    height:"Height",
    fillLength: 'Please Fill In The Length',
    fillWidth: 'Please Fill In The Width',
    fillHeight: 'Please Fill In The Height',
    lengthplaceholder:"Please Enter Goods Length",
    widthplaceholder:"Please Enter Goods Width",
    heightplaceholder:"Please Enter ",
    compute:"Goods Height",
    result:"Calculation Result",
    notice1: "01: Additional Charges Gor Remote Areas, Please Click Here For Individual HK Carriers' charges。",
    notice2: '02: Multiple Consolidation Shipping, No Limitation On The Number Of Goods。',
    notice3: '03: The maximum length Of The Goods Sent Out Should Not Exceed 200CM, And The Total Weight Should Not Exceed 300KG。',
    notice4: '04: If The Weight Of A Single Piece Of Goods Exceeds 45KG, Due To Handling Difficulties, The Express Company Will Charge An Additional Fee Ranging From 50-300RMB, Hhe Heavier The Weight Of A Single Item, The Higher The Overweight Fee。',
    notice5: '05: If The Length Of A Single Piece Of Goods Exceeds 150CM, Due To Delivery Vehicle Restrictions, The Express Company Will Charge An Additional Fee Ranging From 50-300RMB, The Longer The Length Of A Single Item, The Higher The Overlength Fee。',
  },
  footerBox: {
    title1: 'Explore Infinite Possibilities',
    title2: 'Download Our App With One Click',
    title3: 'Make Consolidation More Convenient!',
    installIOS: 'Install IOS',
    installAndroid: 'Install Android',
    features1: 'Cargo Management, Order Management, Latest News, Instant Messaging',
    features2: 'Map Search Pick-Up Point',
    features3: 'Instant Logistics Tracking',
    // 其他翻譯...
  },
  noticelayout:{
    title:"Consolidation Shipping Instructions"
  },
  layout: {
    consolidation: {
      tutorial: 'Novice Teaching',
      shippingCalculation: 'Trial Freight',
      volumeConversion: 'Volume Conversion',
      precautions: 'Matters Needing Attention',
      faq: 'FAQ',
      prohibitedItems: 'Transportation And Storage Of Prohibited Goods'
    },
    service: {
      aboutUs: 'About Us',
      privacyStatement: 'Privacy Protection Statement',
      termsAndDisclaimer: 'Terms Of Use And Disclaimer',
      carriageAgreement: 'Carrier Contract Terms',
      contactUs: 'Contact Us'
    },
    member: {
      overview: "Member Information Overview",
      accountSettings: "Account Settings",
      flow: "Wallet Transaction History",
      AgencyPromotion: "Agency And Promotion",
      AgentCenter: "Agency Center",
      recommend: "Recommend Friends",
      myCoupons: "My Coupons",
      privateMessages: "Private Message",
      myDomesticWarehouse: "Consolidation Shipping Management",
      pickupPoint: "Pick-Up Point Supply",
      domesticWarehouseAddress: "My Delivery Address",
      claimGoods: "Package forecast",
      mergeGoods: "Consolidate Goods",
      orderList: "Order List",
      myOverseasWarehouse: "My Overseas Warehouse",
      overseasWarehouseAddress: "Overseas Warehouse Address",
      goodsDeclaration: "Goods Declaration",
      mergeGoodsOverseas: "Consolidate Goods",
      myOrdersOverseas: "My Orders",
      notificationSettings: "Notification Settings",
      addedType: "Value Added Service Types",
      addedList: "Value Added Service List",
      AddedService: "Value Added Services",
      shopee: 'Shrimp skin related',
      shopeeShop: 'Store Management',
      shopeeOrder: 'order management'
    }
  },
  service:{
    title:"Charges And Services",
    aboutUs: 'About Us',
    privacyStatement: 'Privacy Protection Statement',
    termsAndDisclaimer: 'Terms Of Use And Disclaimer',
    carriageAgreement: 'Carrier Contract Terms',
    contactUs: 'Contact Us'
  },
  rich:{
    title:"Help Center",

  },
  advantagebox:{
    title:"Our Advantages"
  },
  corebox:{
    title: "Service Philosophy",
    title1: "Efficient and Secure",
    notices1: "We are committed to optimizing the logistics process, providing quick responses, strictly implementing safety measures, adopting high standards of packaging and handling, and offering comprehensive cargo insurance services to ensure the safety and punctuality of goods throughout the logistics chain.",
    title2: "Quality Assurance",
    notices2: "We adhere to high-quality service standards to create value for customers, utilizing advanced information systems and professional logistics teams to track cargo dynamics in real-time, ensuring the accuracy and transparency of logistics information.",
    title3: "Personalized and Environmentally Friendly",
    notices3: "Tailoring personalized packaging solutions that are professional, environmentally friendly, and damage-resistant to different industry characteristics and customer needs. We offer various transportation insurance options and value-added services. We actively fulfill social responsibilities, advocate green logistics concepts, and strive to reduce environmental pollution and energy consumption during the logistics process.",
    title4: "Continuous Improvement",
    notices4: "Keeping pace with industry trends, continuously introducing new logistics technologies and management models, continuously optimizing service systems, and dedicated to providing customers with up-to-date, high-quality international logistics service experiences."
  },
  logistics:{
    search:"Query",
    records:"History Record",
    clearrecord:"Clear Record",
    placeholder: "Please Enter Waybill Number"
  },
  account:{
    passwordChangeSuccess: 'Password Successfully Changed, Please Re-login',
    enterCorrectPhoneNumber: 'Please Enter The Correct Phone Number',
    copySuccess: 'Copy Successful！',
    copyTextFailed: 'Copy Text Failed',
    confirmDelete: 'This Operation Will Permanently Delete This Account, Do You Want To Continue?',
    prompt: 'Prompt',
    confirm: 'Confirm',
    enterPassword: 'Please EnterPassword',
    deleteAccount: 'Cancel Account',
    passwordNotEmpty: 'The Password Cannot Be Empty',
    deleteRequestSuccess: 'Cancellation Request Submitted Successfully! Logging In Before {time} Will Cancel The Cancellation',
    userChangeSuccess: 'User Information Updated Successfully！',
    editAvatar: "Modify avatar",
    filters: {
      female: 'Female',
      male: 'Male',
      secret: 'Confidential',
      unlimited: "Unlimited",
    },
    rules: {
      enterOldPassword: 'Please Enter The Original Password',
      passwordLength: 'The Password Length Must Not Be Less Than 6 Characters',
      enterNewPassword: 'Please Enter A New Password',
      passwordMismatch: 'The Passwords Entered Twice Are Not Consistent',
      enterUserName: 'Please EnterUsername',
      selectGender: 'Please Select Gender',
      enterEmail: 'Please Enter Email Box',
      enterPhoneNumber: 'Please Enter Phone Number'
    },
    listData: {
      memberNumber: 'Member Number',
      taobaoAccount: 'Taobao Account',
      userName: 'User Nickname',
      gender: 'Gender',
      email: 'Email',
      loginPassword: 'Login Password',
      phoneNumber: 'Phone',
      avatar: 'Avatar'
    },
    title:"Personal Information Settings",
    accountsecurity:"Account Security",
    password:"Modify Password",
    mailbox:"Verify Email",
    modifyemail:"Modify Email",
    cancellation:"Account Cancellation",
    editaccount:"Edit Account Information",
    name:"User Nickname",
    sex:"Gender",
    pleaseselect:"Please Select",
    secrecy:"Confidential",
    male:"Male",
    female:"Female",
    unlimited: "Unlimited",
    email:"Email",
    phone:"Phone",
    phonenumber:"Mobile Phone Number",
    confirmupdate:"Confirm Modification",
    cancel:"Cancel",
    oldpassword:"Original Password",
    newpassword:"New Password",
    confirmpassword:"Confirm Password",
  },
  address:{
    common: {
      prompt: 'Prompt',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    AddressSelect: {
      title: "Consignee Information",
      add: "Add New Delivery Address",
      expand: "Expand",
      edit: "Edit",
      delete: "Delete",
      retract: "Collapse Address"
    },
    selectServiceType: 'Please select a service type',
    confirmDelete: 'This Operation Will Permanently Delete This Address, Do You Want To Continue?',
    deleteSuccess: 'Deletion Successful!',
    addSuccess: 'Address Added Successfully！',
    editSuccess: 'Address Modified Successfully！',
    areas: "Area",
    SetDefaultAddress: 'Set As Default',
    defaultSuccess: "Settings Successful",
    TypeText: "Type",
    editAddress: 'Edit Address',
    addAddress: 'Add New Address',
    selectagent: 'Pick-Up Point',
    selectCountry: 'Please Select Country',
    selectProvince: 'Please Select Province',
    selectCity: 'Please Select City',
    selectDistrict: 'Please Select District',
    enterRecipientInfo: 'Please Enter Recipient Information',
    enterContactPhone: 'Please Enter Contact Phone Number',
    enterDetailedAddress: 'Please Enter Detailed Address',
    IsDefaultAddress: "Default Address",
    selectAgentPoint: 'Please Select Pick-Up Point Address',
    pickup: "Self Pick-Up",
    dispatch: "Delivery",
    serviceType: "Service Type",
    title:"My Delivery Address",
    all:"All Addresses",
    recipientname:"Recipient Name",
    recipientphone:"Contact Telephone",
    recipientaddress:"Detailed Address",
    destinationname:"Destination",
    operate:"Operation",
    edit:"Edit",
    delete:"Delete",
    add:"Add Address",
    disposition:"Configuration Management",
    role:"Role Management",
    nodistrict:"Non-Commercial Area",
    district:"Commercial Area",
    destination:"Destination",
    selectdestination:"Please Select Destination",
    province:"Province",
    selectprovince:"Province",
    city:"City",
    selectcity:"City",
    area:"Area",
    selectarea:"Area",
    cancel:"Cancel",
    confirmupdate:"Confirm Modification",
    Confirmaddition:"Confirm Addition",
    Dispatch: "Delivery",
    SelfPickup: "Self Pick-Up"
  },
  singlecard:{
    code:"Please Enter Express Number",
    goodsname:"Please Enter Goods Name",
    goodstype:"Goods Type",
    account:"Quantity Of Goods",
    price:"Unit Price Of Goods",
    totalprice:"Total Price Of Goods",
    clientrem:"Remarks",
    warehouseNumber: 'Warehouse Entry Number',
  },
  apply:{
    warehouseNumber: 'Warehouse Entry Number',
    enterExpressNumber: 'Please Enter Express Number',
    enterGoodsName: 'Please Enter Goods Name',
    selectGoodsType: 'Please Enter Goods Type',
    enterGoodsQuantity: 'Please Enter Quantity Of Goods',
    enterUnitPrice: 'Please Enter Unit Price Of Goods',
    enterTotalPrice: 'Please Enter Total Price Of Goods',
    enterInformation: 'Please Fill In The Information',
    fillInCompleteInformation: 'Please Complete The Information Fully',
    reportSuccess: 'Report Submitted Successfully！',
    noticeTitle: 'Warm Reminder',
    notice1: 'The Pre-notification Tracking Number Is Obtained After Your Order Is Successfully Placed On The Shopping Website And Confirmed By The Website For Logistics Dispatch. Pre-notifying Us Will Enhance The Management Of Warehouse Package Receipt And Dispatch, Allowing Us To Process Your Package More Efficiently。',
    notice2: '【General Cargo】Unbranded, Non-electrical, Non-magnetic Electronic Products And Accessories, As Well As Clothing Items...…',
    notice3: '【Food Special Line】The Food Channel Cannot Transport Pure Liquids, Pure Powders, Batteries, Motors, Meats, Egg Products, And The Like',
    notice4: '【Special A】Dash Cams, Media Players, Story Discs, DVDs, DVs, USB Drives, MP3/45 Watches, Motor Batteries, Remote-controlled Toys, Electric Toy Cars, Walkie-talkies, Speakers, Counterfeit Brands... contact Lenses, Colored Contact Lenses, Tea, Cosmetics, Food, Liquids, Powders……',
    notice5: '【Special C】Pharmaceuticals, Tablet Computers, Mobile Phones .……',
    title:"Request for Pre-notification",
    individualforecast:"Individual Pre-notification",
    batchforecast:"Batch Pre-notification",
    code:"Express Tracking Number",
    goodsname:"Goods Name",
    goodstype:"Goods Type",
    account:"Goods Quantity",
    price:"Goods Unit Price",
    totalprice:"Goods Total Price",
    clientrem:"Remarks",
    add:"Add",
    remove:"Remove",
    submit:"Submit"
  },
  added: {
    type: "Service Target",
    name: "Service Name",
    search: "Search",
    TypeTxt: "Service Type",
    Price: "Service Fee",
    Rem: "Service Description",
    order: "Order",
    package: "Package",
    PayState: "Payment Status",
    PayTime: "Payment Time",
    IsHandle: "Handling Status",
    HandleTime: "Handling Time",
    CreateTime: "Creation Time",
    PackageType: "Tracking Number Type",
    paid: "Paid",
    unpaid: "Unpaid",
    handle: "Handled",
    Unprocessed: "Unprocessed",
    placeholderCode: "Order/Package Number",
    OrderId: "Number",
    orderCode: "Order number",
    billCode: "Express tracking number"
  },
  goodcard:{
    goodsname:"Package Name",
    weight:"Weight(KG)",
    code:"Express Tracking Number",
    volumeweight:"Volumetric Weight(KG)",
    size:"Size",
    day: "Days",
    remaining: "Remaining Exempt Storage",
    ProblemTypeName: "Problem Item Type",
    Rem: "Description",
    HandleStateName: "Handling Status",
    HandleRem: "Handling Explanation",
    HandleTime: "Handling Time",
    GoodsTypeName: "Goods Type",
    Account: "Quantity"
  },
  domestic:{
    showImage: "View Image",
    confirmDelete: 'This Operation Will Permanently Delete The Package ({goodsName}). Do You Want To Continue?',
    placeholder: "Please Enter The Express Tracking Number(s), Separated By Spaces If There Are Multiple Numbers",
    search: "Search",
    prompt: 'Prompt',
    CreateTime: "Create Time",
    confirm: 'Confirm',
    deleteSuccess: 'Deletion Successful',
    cancelDelete: 'Cancelled Deletion',
    updateSuccess: 'Modification Successful！',
    selectMergePackages: 'Please First Select The Packages To Be Merged And Placed Together！',
    diffWarehouse: 'Cannot Apply For Different Warehouses！',
    unstoredGoods: 'Cannot Apply For Goods Not Yet Received Into Storage！',
    UpdateRules: {
      GoodsName: {
        required: 'Please Enter The Name Of Goods',
      },
      GoodsTypeName: {
        required: 'Please Select Goods Type',
      },
      Account: {
        required: 'Please Enter The Quantity Of Goods',
      },
      Price: {
        required: 'Please Enter The Price Of Goods',
      },
    },
    all:"All Goods",
    waitPayment: "Pending Order",
    goodsinfo:"Goods Information",
    status:"Warehouse Status",
    arrived:"Has arrived at The warehouse",
    unarrive:"Has not arrived at The warehouse",
    claim: 'To be claimed',
    forecast: 'claimed',
    account:"Quantity (pieces)",
    warehousename:"Warehouse Name",
    goodClaim: 'Claim of goods',
    operate:"Operation",
    edit:"Edit",
    delete:"Delete",
    selection:"Selected",
    addup:"Cumulative(KG)",
    overlength:"Length Exceeded(M)",
    combine:"Merge And Place Order",
    disposition:"Configuration Management",
    role:"Role Management",
    combinefleight:"Consolidate Goods",
    editinfo:"Edit Information",
    goodsname:"Package Name",
    goodstype:"Goods Type",
    selectgoodstype:"Please Select Goods Type",
    goodsaccount:"Quantity",
    goodsprice:"Price",
    clientrem:"Remarks",
    confirmupdate:"Confirm Modifications",
    cancel:"Cancel",
  },
  consolidation: {
    noticeTitle: 'Precautions',
  },
  coupon:{
    effectivetime:"Effective Time",
    expiredtime:"Expiry Time",
    use:"Use Immediately",
    pointscashroll:"Points And Cash Vouchers",
    cashroll:"Cash Voucher",
    pointexchange:"Points Exchange",
    exchange:"Exchange",
    discountcoupon:"Coupon",
    periodOfValidity: "validity period",
    to: "to",
    integral: "integral",
    exchangeSuccess: "exchange successful",
    title: 'Use points to redeem coupons, do you want to continue?',
    notice: "Effective within {0} days from the redemption date",
    minPrice: "Minimum usage price",
    MinWeight: "Minimum weight for use"
  },
  declare:{
    rules: {
      courier: {
        required: 'Please Enter The Express Tracking Number',
      },
      storehouse: {
        required: 'Please Select Overseas Warehouse',
      },
      company: {
        required: 'Please Select The Courier Company',
      },
      type: {
        required: 'Please Select Goods Type',
      },
      classification: {
        required: 'Please Select Goods Type',
      },
      goodsName: {
        required: 'Please Enter The Name Of Goods',
      },
      goodsNum: {
        required: 'Please Enter The Quantity Of Goods',
      },
      currency: {
        required: 'Please Select Currency Type',
      },
      value: {
        required: 'Please Fill In The Value Of Goods',
      },
    },
    title:"Declaration Of Goods From Overseas To Hong Kong",
    code:"Express Tracking Number",
    codeplaceholder:"Please Enter The Express Tracking Number",
    findcode:"How to Find The Express Shipping Waybill Number？",
    storehouse:"Overseas Warehouse",
    company:"Courier Company",
    type:"Goods Type",
    classification:"Category Classification",
    goodsname:"Goods Name",
    goodsNum:"Goods Quantity",
    currency:"Currency Type",
    value:"Goods Value",
    reset:"Reset",
    submit:"Submit",
    placeholderMark: "Note: Explanatory Text Description",
    notice1: 'When Declaring Goods, Please Correctly Select The Overseas Warehouse. If The Tracking Number Is Correct But The Warehouse Is Not, The Status Will Continuously Show As Unreceived。',
    notice2: 'Please Use English Names For The Name Of Goods Input。',
  },
  common: {
    title: "Comment",
    submit: "Submit",
    imageValidate: "The image size cannot exceed 5MB!"
  },
  notify:{
    title:"APP Push Message",
    emailmsg:"Email Notification",
    unverified:"We Have Detected That Your Mailbox Has Not Been Verified",
    latestnotice:"Latest Promotion Notification",
    intelligentservice:"Intelligent Services",
    rem:"Goods Will Be Automatically Arranged To Your Account After Arrival At The Warehouse"
  },
  RightBox: {
    title: "Back To Top"
  },
  order:{
    deleteOrder: {
      confirm: 'This Operation Will Permanently Delete The Order With The Number ({orderId}), Do You Want To Continue?',
      prompt: 'Prompt',
      confirmText: 'Sure',
      cancelText: 'Cancel',
      success: 'Successfully deleted',
      cancelDelete: 'Has Been Cancelled And Deleted',
    },
    deliveryOrder: {
      confirm: 'This operation will confirm the order with delivery number ({0}). Do you want to continue?',
      prompt: 'Prompt',
      confirmText: 'OK',
      cancelText: 'Cancel',
      success: 'Received goods successfully',
      cancel: 'Harvest canceled',
    },
    shipmentCode: "Shipping Order Number",
    delivery: "Confirm receipt",
    CreateTime: "Order Placement Time",
    placeholder: "Please Enter Order Number",
    trajectory: 'Logistics records',
    confirm: 'Confirm',
    emptyTrajectory: "No logistics records",
    Yes: 'Yes',
    No: "No",
    rate: 'Rating',
    Weight: "Weight(KG)",
    title:"Order Information",
    mainbillcode:"Order Number",
    id:"ID",
    Export: "Export",
    ExportOrder: "Order Export",
    Separator: "To",
    StartTime: "Start date",
    EndTime: "End date",
    orderid:"Order Number",
    carrierbillcode:"Consignee Number",
    goodsname:"Cargo Name",
    receiveinfo:"Recipient Information",
    receivename:"Recipient Name",
    receivephone:"Contact Cell Phone",
    receivetelephone:"Contact Number",
    receiveaddress:"Delivery Address",
    receiveidcard:"Id Card",
    receivepostcode:"Postcode",
    recipientaddress:"Delivery address",
    destination:"Destination",
    selectdestination:"Please Choose Destination",
    province:"Province",
    selectprovince:"Please Choose Province",
    city:"City",
    selectcity:"Please Choose City",
    area:"Area",
    selectarea:"Please Choose Area",
    warehouse:"Warehouse",
    goodstype:"Goods Type",
    timer:"time-effective",
    carrier:"Carrier",
    orderrem:"Order Notes",
    deliverytitle:"Shipping Information",
    ordercode:"Shipment Number",
    logistics:"Logistics Company",
    packagetitle:"Order Parcel Information",
    rem:"Additional Remarks",
    account:"Quantity",
    daysinstorage:"Days Until Arrival At Warehouse",
    size:"Size",
    weight:"Actual Weight",
    surcharge:"Additional Fee",
    count:"Total Pieces",
    grossweight:"Total Weight",
    grosssurcharge:"Total Additional Charges",
    storagecharges:"Rental Expense",
    weightcharges:"Overweight Charge",
    lengthcharges:"Overlength Charge",
    fareweight:"Total Chargeable Weight",
    receivinginfo:"Consignee Information",
    declarationinfo:"Declarant Information",
    declarename:"Declarant Name",
    declarePhone:"Contact Mobile Phone",
    declaretelephone:"Contact Telephone Number",
    declareidcard:"Id Card",
    declarepostcode:"Postcode",
    declareaddress:"Declaration Address",
    declareaccount:"Declared Quantity",
    declareprice:"Declared Value",
    feeinfo:"Fee Information",
    collectionamount:"COD",
    taxfee:"Duty And Taxes Fee",
    insuredamount:"Insured Value Amount",
    couponamount:"Coupon",
    integralamount:"Points Redemption Amount",
    destinationcharges:"Destination Charges",
    istax:"Is Duty Included",
    istopay:"Is Freight Collect",
    totalfee:"Total Fees",
    freight:"Freight Cost",
    collectioncommission:"COD Service Fee",
    insuredcommission:"Insurance Service Fee",
    addresssurcharge:"Address Additional Charge",
    addedservicefee:"Value-Added Service Fee",
    discount:"Enjoy Discounts",
    off:"Discount",
    accruedcharge:"Destination country currency({0})",
    paytype:"Payment Method",
    payment:"Payment",
    paystate:"Payment Status",
    cashier: "Cashier Counter",
    paid:"Paid",
    nonpayment:"Unpaid",
    createtime:"Order Placement Time",
    printer:"Print",
    domestic:"All Orders",
    operate:"Operation",
    details:"Detail Information",
    delete:"Delete",
    selection:"Selected",
    deleteorder:"Delete Order",
    disposition:"Configuration Management",
    role:"Role Management",
    orderdetails:"Order Details",
    underway:"To be shipped",
    shipped: "Shipped",
    completed:"Completed",
    unPaid: "Unpaid",
    orderSourceName: "order source"
  },
  recharge: {
    title: "Balance Recharge",
    balance: "Balance",
    money: "Recharge Amount",
    confirmMoney: "Amount",
    submit: "Confirm Recharge",
    warning: "Please Select The Recharge Amount！",
    inventory: {
      title: "Order Details: Balance Recharge",
      balance: "Account Balance",
      pon: "Virtual Order Number"
    }
  },
  pickupPoint: {
    Name: "Pick-Up Point Name",
    TypeText: "Type",
    DestinationName: "Destination",
    ProvinceName: "Province",
    CityName: "City",
    AreaName: "Area",
    Address: "Detailed Address",
    Phone: "Contact Mobile Phone",
    Tel: "Contact Telephone Number",
    BusinessHours: "Business Hours",
    Postcode: "Postcode",
    ServiceCharge: "Service Fee",
    StorageLife: "Storage Period",
    Longitude: "Longitude",
    Latitude: "Latitude",
    Rem: "Remarks And Instructions",
    AgentStateText: "Development Status",
    Add: "Add to shipping address"
  },
  line: {
    title: "Dedicated Line Order",
    loadingText: "Submitting form...",
    ClientCode: "Client Order Number",
    GoodsName: "Goods Name",
    DestinationId: "Destination",
    placeholder: "Please select",
    GoodsTypeId: "Goods Type",
    CarrierId: "Carrier",
    TimerId: "Timeliness",
    WareHouseId: "Receiving Warehouse",
    RecipientName: "Recipient Name",
    RecipientPhone: "Recipient Phone",
    RecipientEmail: "Recipient Email",
    RecipientPostCode: "Recipient Postal Code",
    RecipientAddress: "Recipient Address",
    Rem: "Remarks",
    submitForm: "Place Order Now",
    resetForm: "Reset",
    placeholderDestinationId: "Please select destination",
    placeholderGoodsTypeId: "Please select goods type",
    placeholderCarrierId: "Please select carrier",
    placeholderTimerId: "Please select timeliness",
    placeholderWareHouseId: "Please select warehouse",
    placeholderClientCode: "Please enter client order number",
    placeholderGoodsName: "Please enter goods name",
    placeholderRecipientAddress: "Please enter recipient address",
    placeholderRecipientPhone: "Please enter recipient phone",
    placeholderRecipientName: "Please enter recipient name",
    submitSuccess: "Order placed successfully!!!"
  },
  personal:{
    withdrawalMoney: {
      Amount: "Amount",
      BankName: "Bank of Deposit",
      OpenName: "Account Holder Name",
      BankCard: "Bank Card Number",
      submit: "Submit Withdrawal",
      cancel: "Cancel",
      title: "Withdraw Balance (Account Balance: {0})",
      placeholderAmount: "Please enter withdrawal amount",
      placeholderOpenName: "Please enter account holder name",
      placeholderBankName: "Please enter Bank of Deposit",
      placeholderBankCard: "Please enter bank card number",
      withdrawalSuccess: "Withdrawal request submitted!"
    },
    Rem: "Rem",
    sign: "Sign In",
    signCalendar: "Check in Calendar",
    signSuccess: "Sign in success reward points ({0})",
    withdrawalMoneyTitle: "Withdrawal",
    Package: "My package",
    Order: "My order",
    notice: "Announcement",
    confirm: "Confirm",
    balance: "Balance Recharge",
    domestic:"Domestic Warehouse",
    address: "My Delivery Address",
    recharge: "Recharge",
    arrived:"Has Arrived At The Warehouse",
    recommend: "Refer A Friend",
    commission: "Earn Commission",
    withdrawal: "Withdrawable Commission",
    unarrive:"Not Yet At The Warehouse",
    notplaceorder:"Pending order placement",
    placedorder:"To be claimed",
    domesticaddress:"Domestic Warehouse Exclusive Address",
    cargoclaim:"Cargo Claiming",
    combinedcargo:"Merge Cargo",
    myorder:"My Orders",
    username:"Member Name",
    amount: "Account Balance",
    membernumber:"Membership Number",
    accountnumber:"Account Number",
    deliveryaddress:"Delivery Address",
    mycoupon:"My Coupons",
    recommendedfriend:"Refer a Friend",
    integral:"Integral",
    coupon:"Available Coupons",
    membercenter:"Consolidation Shipping Center",
    ContactPerson: "Contact Person Name",
    Phone: "Contact Telephone Number",
    PostCode: "Postal Code",
    DetailedAddress: "Destination Address",
    copyAddress: "One-Click Address Copy",
    title: "Exclusive Address for Consolidation Shipping Warehouse",
    pendingPayment: "Pending Payment",
    toBeShipped: "To Be Shipped",
    shipped: "Shipped",
    completed: "Completed",
  },
  procedure:{
    demonstrate: {
      step1: {
        title: 'Register an Account to Become a Member',
        subTitle: ''
      },
      step2: {
        title: 'Obtain And Select The Consolidation Shipping Warehouse Address As The Delivery Address For Your Shopping Platform',
        subTitle: ''
      },
      step3: {
        title: 'Claim The Purchased Goods On Our Website Platform',
        subTitle: ''
      },
      step4: {
        title: 'After The Goods Arrive At The Warehouse, Apply For Dispatch',
        subTitle: ''
      },
      step5: {
        title: 'Goods Arrive At The Destination, Available For Self-pickup Or Home Delivery',
        subTitle: ''
      }
    },
    servers: {
      step1: {
        title: 'Goods Self-Pickup',
        introduces: [
          'With Over 300+ Self-pickup Partner Locations, Choose The One That Best Suits You To Collect Your Goods!\n' +
          'Service Advantages: Cost-saving, Flexible Scheduling, Control Over The Delivery Process',
          '·  Ship The Goods To A Designated Logistics Point。',
          '· After The Logistics Company Delivers The Goods, They Notify The Recipient。',
          '· The Recipient, With Valid Identification And The Pickup Code Or Courier Tracking Number Or Other Relevant Information, Goes To The Designated Location To Handle The Pickup Procedures。'
        ]
      },
      step2: {
        title: 'Delivered to Your Doorstep',
        introduces: [
          'The Courier Delivers The Package Within The Agreed Timeframe',
          'And Completes The Signing-off Procedures With The Recipient'
        ]
      },
      step3: {
        title: 'Live Tracking Of The Logistics Trajectory',
        introduces: [
          'When The Status Of An Order Tracking Changes, The System Will Update In Real-time And Synchronize To Our Inquiry Platform',
          'By Entering The Unique Logistics Tracking Number On The Member Center Or Mobile APP, You Can View The Detailed Order Tracking Information',
          'Available 24/7, Regardless Of Time And Place, You Can Always Check The Location And Status Of Your Goods'
        ]
      },
      // 其他翻譯...
    },
    process:"Consolidation Shipping Process",
    learnmore:"Learn more",
    checkMore: "View Warehouse Address",
    warehouseIntroduction: 'Consolidation Shipping Warehouse',
    warehouseLocation: 'Having A Modern Warehouse Exceeding 10,000 Square Meters With Clearly Demarcated Sections Tailored For The Storage Needs Of Different Types Of Goods Is Advantageous. The Geographical Location In A Transportation Hub Area, Adjacent To Ports, Airports, Or Major Road Networks, Ensures That Goods Can Be Collected And Distributed Quickly And Conveniently. This Significantly Reduces Transit Times And Lowers Logistics Costs',
    warehouseFunction: 'Adopting Advanced Automated Warehouse Technology Enables Efficient And Precise Inventory Management, Including Entry And Exit Processes, As Well As Stock Control. With 24-hour Video Surveillance, Comprehensive Fire Protection Systems, And A Dedicated Professional Security Team In Place, The Safety Of The Goods Is Ensured Without Any Worries',
    learnMore: 'Learn more',
    weightCalculation: 'Chargeable weight',
    weightCalculationNote1: 'In Freight Calculation, The Charged Weight Is Based On Two Elements: Actual Gross Weight And Volumetric Weight. Actual Gross Weight Refers To The Weight Of The Entity Of The Goods; Volume Weight Is An Equivalent Weight Calculation Method Considering The Package Is Light But Large。',
    weightCalculationNote2: 'Actual Gross Weight: Obtained By Directly Weighing The Goods。',
    weightCalculationNote3: 'Volume Weight: Calculated By The Formula (Length x width x height ÷ billing factor), And There Are Different Billing Factor Standards According To The Mode Of Transportation。',
    weightCalculationNote4: 'Freight Will Be Charged On The Basis Of The Greater Of Actual Gross Weight And Volumetric Weight. In Addition, For Some Special Goods Or Services, There May Be Additional Provisions Such As Minimum Billed Weight, Extra Excess Weight Surcharges, Etc. If You Have Any Questions Or Need A Detailed Quote, Please Feel Free To Contact Our Customer Service Team。',
    declarationTitle: 'Cargo Declaration',
    declarationNote1: 'Ensure That All Goods Information Such As Name, Specification, Quantity, Value, Etc. Are Exactly The Same As The Actual Goods, And Provide Complete And True Commercial Invoices, Packing Lists And Relevant Certificates, So That The Import And Export Of Goods Can Be Declared Smoothly And Effectively Reduce The Risk Of Customs Clearance',
    shippingPackage: 'Value-added Service',
    packageNote1: 'If Your Goods Are Too Bubbly And Need To Be Unpacked, Or The Outer Packaging Is Polygonal And Cannot Be Squeezed Into The International Consolidation Shipping Box, Then We Offer Free Repacking Service. The Volume Will Be Reduced After Repackaging, But Not Absolutely。',
  }
}
